import React, { useState, useEffect } from "react";
import Base from "../Config/Base";
// import OrderImg from '../../Assests/Images/fasion.jpg';
import { BASE_API_URL } from "../Config/Config";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';
// import AddToCart from "../Cart/AddCart";
import Swal from 'sweetalert2';


export default function MyAccount () {

    useEffect(() => {
            window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const activeContentFromQuery = searchParams.get('content');
    const [userData, setUserData] = useState([]);
    const [activeContent, setActiveContent] = useState(activeContentFromQuery || 'myDetailsContent');
    const [cartItems, setCartItems] = useState([]);
    const [myOrders, setMyOrders] = useState([]);
    const [favItems, setFavItems] = useState([]);
    // const [quantities, setQuantities] = useState([]);
    const [address, setAddress] = useState([]);
    const [selectedType, setSelectedType] = useState("shipping");
    const [homeAddresses, setHomeAddresses] = useState([]);
    const [officeAddresses, setOfficeAddresses] = useState([]);  
    const [otherAddresses, setOtherAddresses] = useState([]);  
    const [viewOrderDetails, setViewOrderDetails] = useState({});  
    const [isViewOrder, setIsViewOrder] = useState(false);  
    const [cartQuantities, setCartQuantities] = useState([]);

    useEffect(() => {
        const storedUserData = sessionStorage.getItem('UserData');
        if (storedUserData) {
            try {
                setUserData(JSON.parse(storedUserData));
            } catch (error) {
                console.error("Error parsing storedUserData:", error);
            }
        }
        // console.log(userData);
    }, []);
    // console.log(viewOrderDetails)
    
    const handleContentChange = (content) => {
        setActiveContent(content);
    };

    useEffect(() => {
        fetchCartItems();
        fetchFavItems();
        fetchAddress();
        fetchMyOrders();
        fetchTransactions();
    }, [userData]);

    const fetchCartItems = async () => {
        try {
            const formData = new FormData();
            formData.append("user_id", userData.id);

            const response = await axios.post(`${BASE_API_URL}get_user_cart`, formData, {
                headers: {
                    Authorization: `Bearer ${userData.apikey}`,
                    // Authorization: `${JWT_TOKEN_POST}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            setCartItems(response.data.data);
            // console.log(response.data.data)
        } catch (err) {
            console.log('failed fetching cart items..!');
        }
    };

    const fetchMyOrders = async () => {
        try {
            const formData = new FormData();
            formData.append("user_id", userData.id);

            const response = await axios.post(`${BASE_API_URL}get_orders`, formData, {
                headers: {
                    Authorization: `Bearer ${userData.apikey}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            setMyOrders(response.data.data);
            // console.log(response.data.data);
        } catch (err) {
            console.log('failed fetching cart items..!');
        }
    };

    const fetchFavItems = async () => {
        try {
            const formData = new FormData();
            formData.append("user_id", userData.id);

            const response = await axios.post(`${BASE_API_URL}get_favorites`, formData, {
                headers: {
                    Authorization: `Bearer ${userData?.apikey}`,
                    // Authorization: `${JWT_TOKEN_POST}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            setFavItems(response.data.data);
        } catch (err) {
            console.log('failed fetching fav items..!');
        }
    };

    const fetchAddress = async () => {
        try {
            const formData = new FormData();
            formData.append("user_id", userData.id);

            const response = await axios.post(`${BASE_API_URL}get_address`, formData, {
                headers: {
                    Authorization: `Bearer ${userData?.apikey}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            setAddress(response.data.data);
        } catch (err) {
            console.log('failed fetching fav items..!');
        }
    };

    useEffect(() => {
        if (!address || !Array.isArray(address)) {
            console.warn("Address is not defined or is not an array.");
            setHomeAddresses(null);
            setOfficeAddresses(null);
            setOtherAddresses(null);
            return;
        }
    
        const home = address.filter((item) => item.type === "home");
        const office = address.filter((item) => item.type === "office");
        const other = address.filter((item) => item.type === "other");
        
        setHomeAddresses(home.length > 0 ? home[0] : null);
        setOfficeAddresses(office.length > 0 ? office[0] : null);
        setOtherAddresses(other.length > 0 ? other[0] : null);
    }, [address]);
    
    const ClearFavItem = async (id) => {
        try {
            const formData = new FormData();
            formData.append("user_id", 26);
            formData.append("product_id", id);

            const response = await axios.post(`${BASE_API_URL}remove_from_favorites`, formData, {
                headers: {
                    Authorization: `Bearer ${userData.apikey}`,
                    // Authorization: `${JWT_TOKEN_POST}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            fetchFavItems();
        } catch (err) {
            console.error('Failed fetching favorite items:', err);
        }
    };

    const ClearAllFavItems = async () => {
        try {
            const formData = new FormData();
            formData.append("user_id", 26);

            const response = await axios.post(`${BASE_API_URL}remove_from_favorites`, formData, {
                headers: {
                    Authorization: `Bearer ${userData.apikey}`,
                    // Authorization: `${JWT_TOKEN_POST}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            fetchFavItems();
        } catch (err) {
            console.error('Failed fetching favorite items:', err);
        }
    };

    const ClearCartItem = async (id) => {
        try {
            const formData = new FormData();
            formData.append("user_id", userData.id);
            formData.append("product_variant_id", id);

            const response = await axios.post(`${BASE_API_URL}remove_from_cart`, formData, {
                headers: {
                    Authorization: `Bearer ${userData.apikey}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            setFavItems(response.data.data);
        } catch (err) {
            console.error('Failed fetching favorite items:', err);
        }
    };

    const ClearAllCartItems = async () => {
        try {
            const formData = new FormData();
            formData.append("user_id", userData.id);

            const response = await axios.post(`${BASE_API_URL}remove_from_cart`, formData, {
                headers: {
                    Authorization: `Bearer ${userData.apikey}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            setFavItems(response.data.data);
        } catch (err) {
            console.error('Failed fetching favorite items:', err);
        }
    };

    const handleViewClick = (viewObj) => {
        setViewOrderDetails(viewObj);
        setIsViewOrder(true);
        console.log(viewObj);
    };

    const [attachments, setAttachments] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [invoiceHtml, setInvoiceHtml] = useState('');
    const [transactions, setTransactions] = useState([]);
    const [cartTotalAmount, setCartTotalAmount] = useState(0);
    const [favQuantities, setfavQuantities] = useState([]);

    useEffect(() => {
        const fetchInvoiceHtml = async () => {
            try {
                const formData = new FormData();
                formData.append("order_id", viewOrderDetails.id);
        
                const response = await axios.post(`${BASE_API_URL}get_invoice_html`, formData, {
                    headers: {
                        Authorization: `Bearer ${userData.apikey}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
        
                setInvoiceHtml(response.data.data);
                } catch (err) {
                    console.log('Failed fetching invoice HTML:', err);
                }
            };
        
        fetchInvoiceHtml();
    }, [viewOrderDetails]);

    const handleFileChange = (event) => {
        setAttachments(event.target.files);
    };

    const handleSubmit = async (event, orderId) => {
        event.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(false);
        // console.log(orderId, attachments)

        const formData = new FormData();
        formData.append("order_id", orderId);

        if (attachments) {
            // Array.from(attachments).forEach((file) => {
                formData.append("attachments", attachments);
            // });
        }

        try {
            const response = await axios.post(`${BASE_API_URL}send_bank_transfer_proof`, formData, {
                headers: {
                    Authorization: `Bearer ${userData.apikey}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            console.log(response.data)
            if (!response.data.error) {
                setSuccess(true);
                // alert("Payment proof sent successfully!");
            } else {
                throw new Error("Failed to send payment proof.");
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const printInvoice = () => {
        if (!invoiceHtml) {
          console.log("Invoice data is not available yet.");
          return;
        }
    
        // Open a new window
        const printWindow = window.open('', '_blank');
        
        // Wait until the HTML content is loaded into the print window
        printWindow.document.write('<html><head><title>Invoice</title></head><body>');
        printWindow.document.write(invoiceHtml); // Inject the HTML content into the new window
        printWindow.document.write('</body></html>');
        printWindow.document.close(); // Close the document to complete rendering
        
        // Make sure content is rendered before printing
        printWindow.onload = () => {
          printWindow.print(); // Trigger the print dialog
        };
    };

    const fetchTransactions = async () => {
        try {
            const formData = new FormData();
            formData.append("user_id", userData.id);

            const response = await axios.post(`${BASE_API_URL}transactions`, formData, {
                headers: {
                    Authorization: `Bearer ${userData.apikey}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            setTransactions(response.data.data);
            // console.log(response.data.data)
        } catch (err) {
            console.log('failed fetching fav items..!');
        }
    };

    useEffect(() => {
        if (cartItems && cartItems.length > 0) {
            setCartQuantities(cartItems.map((item) => item.qty || 1));
        }
    }, [cartItems]);
    
    useEffect(() => {
        const total = (cartItems || []).reduce((sum, item, index) => {
            const price = Number(item.price || 0);
            const quantity = cartQuantities[index] || 1;
            return sum + price * quantity;
        }, 0);
        setCartTotalAmount(total);
    }, [cartItems, cartQuantities]);

    // const handleCartIncrement = (index) => {
    //     setCartQuantities((prev) => {
    //         const updated = [...prev];
    //         updated[index] += 1;
    //         return updated;
    //     });
    // };

    // const handleCartDecrement = (index) => {
    //     setCartQuantities((prev) => {
    //         const updated = [...prev];
    //         updated[index] = Math.max(1, updated[index] - 1);
    //         return updated;
    //     });
    // };

    const handleCartIncrement = (index) => {
        setCartQuantities((prev) => {
            const updated = [...prev];
            updated[index] = (Number(updated[index]) || 1) + 1;
            console.log("Incremented quantity:", updated[index]); // Log new value
            return updated;
        });
    };
    
    const handleCartDecrement = (index) => {
        setCartQuantities((prev) => {
            const updated = [...prev];
            updated[index] = Math.max(1, updated[index] - 1);
            console.log("Decremented quantity:", updated[index]);
            return updated;
        });
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}-${month}-${year} ${hours}:${minutes}`;
    };

    const handleAddToCart = async (qty, productVariantId) => {
        const url = `${BASE_API_URL}manage_cart`;
    
        if (!productVariantId || qty < 1) return alert("Invalid product or quantity");
    
        console.log(productVariantId, qty, 'ID and Qty triggered in cart function');
        try {
            const formData = new FormData();
            formData.append("user_id", userData.id);
            formData.append("qty", qty);
            formData.append("product_variant_id", productVariantId);
    
            console.log("Sending request with API key:", userData.apikey);
    
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${userData.apikey}`,
                },
                body: formData,
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log("Cart response:", data);
    
                if (!data.error) {
                    alert("Product added to cart successfully!");
                    window.location.reload();
                } else {
                    alert("Failed to add product to cart. Error: " + data.message);
                }
            } else {
                console.error("Error adding to cart:", response.statusText);
                alert("Failed to add product to cart.");
            }
        } catch (error) {
            console.error("Error adding to cart:", error);
            alert("An error occurred while adding to cart.");
        }
    };

    const handleCheckout = async () => {
        const userId = userData.id;
    
        const cartData = cartItems.map((item, index) => ({
            product_variant_id: item.id,
            qty: cartQuantities[index] || 1,
            user_id: userId,
        }));
    
        try {
            const promises = cartData.map(async (data) => {
                const formData = new FormData();
                formData.append("product_variant_id", data.product_variant_id);
                formData.append("qty", data.qty);
                formData.append("user_id", data.user_id);
    
                const response = await axios.post(`${BASE_API_URL}manage_cart`, formData, {
                    headers: {
                        Authorization: `Bearer ${userData.apikey}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
    
                // console.log(response.data);
                return response.data;
            });
    
            const results = await Promise.all(promises);
            if (!results[0].error) {
                alert("Cart updated successfully! Redirecting to checkout...");
                navigate('/billing-details');
            }
    
            // console.log("Cart updated successfully:", results);
        } catch (error) {
            console.error("Error updating cart:", error);
            alert("Failed to update the cart. Please try again.");
        }
    };

    useEffect(() => {
        if (favItems && favItems.length > 0) {
            setfavQuantities(favItems.map(() => 1));
        }
    }, [favItems]); 

    const handleFavIncrement = (index) => {
        setfavQuantities((prev) => {
            const updated = [...prev];
            updated[index] += 1;
            return updated;
        });
    };

    const handleFavDecrement = (index) => {
        setfavQuantities((prev) => {
            const updated = [...prev];
            updated[index] = Math.max(1, updated[index] - 1);
            return updated;
        });
    };

    const totalAmount = (favItems || []).reduce((total, item, index) => {
        const price = Number(item.min_max_price?.special_price || 0);
        const quantity = favQuantities[index] || 1;
        return total + price * quantity;
    }, 0);

    const [formData, setFormData] = useState({
        username: userData.username || "",
        email: userData.email || "",
        oldPassword: "",
        newPassword: "",
    });

    useEffect(() => {
        setFormData({
            username: userData.username,
            email: userData.email,
            oldPassword: "",
            newPassword: "",
        })
    }, [userData]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prev) => ({ ...prev, [id]: value }));
    };

    const handleUserSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append("user_id", userData.id);
        formDataToSend.append("username", formData.username);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("old", formData.oldPassword || "");
        formDataToSend.append("new", formData.newPassword || "");
        
        formDataToSend.forEach((value, key) => {
            console.log(`${key}: ${value}`);
        });

        try {
            const response = await fetch(`${BASE_API_URL}update_user`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${userData.apikey}`,
                },
                body: formDataToSend,
            });

            const result = await response.json();
            console.log(result)

            if (!result.error) {
                sessionStorage.setItem("UserData", JSON.stringify(result.data[0]));
                Swal.fire({
                    title: 'Profile Update Successful!',
                    text: `${result.message}`,
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(() => {
                    window.location.reload();
                });
            } else {
                Swal.fire({
                    title: 'Profile Update Failed',
                    text: `${result.message}`,
                    icon: 'error',
                    confirmButtonText: 'Retry'
                });
            }
        } catch (error) {
            console.error("Error updating account details:", error);
            alert("Failed to update account details. Please try again.");
        }
    };
    
    return (
        <Base>
            <div className="bread container my-4">
                <p className="font-rock">My Account <i className="fa-solid fa-chevron-right fa-sm"></i> <span id="breadcrumbText">My Details</span></p>
            </div>
            <div className="container my-md-5">
                <div className="row">
                    <div className="col-md-3 border-right-5 my-3">
                        <p className="fw-bold">Account details</p>
                        <ul className="list-group" id="menu">
                            <li
                                className={`list-group-item ${activeContent === 'myDetailsContent' ? 'active' : ''}`}
                                data-content="myDetailsContent"
                                data-breadcrumb="My Details"
                                onClick={() => handleContentChange('myDetailsContent')}
                            >
                                My details
                            </li>
                            <li
                                className={`list-group-item ${activeContent === 'myWishlistContent' ? 'active' : ''}`}
                                data-content="myWishlistContent"
                                data-breadcrumb="My Wishlist"
                                onClick={() => handleContentChange('myWishlistContent')}
                            >
                                My wishlist
                            </li>
                            <li
                                className={`list-group-item ${activeContent === 'myCartContent' ? 'active' : ''}`}
                                data-content="myCartContent"
                                data-breadcrumb="My Wishlist"
                                onClick={() => handleContentChange('myCartContent')}
                            >
                                My cart
                            </li>
                            <li
                                className={`list-group-item ${activeContent === 'myWalletContent' ? 'active' : ''}`}
                                data-content="myWalletContent"
                                data-breadcrumb="My Wallet"
                                onClick={() => handleContentChange('myWalletContent')}
                            >
                                My transactions
                            </li>
                            <li
                                className={`list-group-item ${activeContent === 'myOrdersContent' ? 'active' : ''}`}
                                data-content="myOrdersContent"
                                data-breadcrumb="My Orders"
                                onClick={() => handleContentChange('myOrdersContent')}
                            >
                                My orders
                            </li>
                            {/* <li
                                className={`list-group-item ${activeContent === 'savedCardsContent' ? 'active' : ''}`}
                                data-content="savedCardsContent"
                                data-breadcrumb="My Saved Cards"
                                onClick={() => handleContentChange('savedCardsContent')}
                            >
                                My saved cards
                            </li> */}
                            {/* <li
                                className={`list-group-item ${activeContent === 'signOutContent' ? 'active' : ''}`}
                                data-content="signOutContent"
                                data-breadcrumb="Sign Out"
                                onClick={() => handleContentChange('signOutContent')}
                            >
                                Sign out
                            </li> */}
                        </ul>
                    </div>

                    <div className="col-md-9 my-3">
                        <div id="contentArea" className="ps-2">
                            {activeContent === 'myDetailsContent' && 
                                <div id="myDetailsContent" className="content-section">
                                    <h2 className="mb-4">MY DETAILS</h2>
                                    <hr/>
                                    <div className="container py-4">
                                        <div className="row">
                                            <div className="col-lg-7 col-md-9">
                                                <form onSubmit={handleUserSubmit}>
                                                    <h5 className="mb-md-4 mb-3 fw-bold">Account details</h5>
                                                    <div className="mb-md-4 mb-3">
                                                        <input
                                                            type="text"
                                                            id="username"
                                                            className="form-control rounded-0 border-secondary"
                                                            value={formData.username}
                                                            onChange={handleInputChange}
                                                            placeholder="User name"
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                    <div className="mb-md-4 mb-3">
                                                        <input
                                                            type="email"
                                                            id="email" 
                                                            className="form-control rounded-0 border-secondary"
                                                            value={formData.email}
                                                            onChange={handleInputChange}
                                                            placeholder="Email"
                                                            
                                                        />
                                                    </div>
                                                    <div className="mb-md-4 mb-3">
                                                        <input
                                                            type="password"
                                                            id="oldPassword"
                                                            className="form-control rounded-0 border-secondary"
                                                            value={formData.oldPassword}
                                                            onChange={handleInputChange}
                                                            placeholder="Password"
                                                            autoComplete="new-password" 
                                                        />
                                                    </div>
                                                    <div className="mb-md-4 mb-3">
                                                        <input
                                                            type="password"
                                                            id="newPassword"
                                                            className="form-control rounded-0 border-secondary"
                                                            value={formData.newPassword}
                                                            onChange={handleInputChange}
                                                            placeholder="Confirm password"
                                                        />
                                                    </div>
                                                    <div className="d-flex">
                                                        <h5 
                                                            className={`mb-md-4 me-5 mb-3 fw-bold ${selectedType === "shipping" ? "text-primary" : ""}`} 
                                                            onClick={() => setSelectedType("shipping")}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Shipping details
                                                        </h5>
                                                        <h5 
                                                            className={`mb-md-4 me-5 mb-3 fw-bold ${selectedType === "office" ? "text-primary" : ""}`} 
                                                            onClick={() => setSelectedType("office")}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Office details
                                                        </h5>
                                                        <h5 
                                                            className={`mb-md-4 mb-3 fw-bold ${selectedType === "other" ? "text-primary" : ""}`} 
                                                            onClick={() => setSelectedType("other")}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Other details
                                                        </h5>
                                                    </div>

                                                    <div className={`${selectedType === 'office' ? 'd-block' : 'd-none'}`}>
                                                        <div className="mb-md-4 mb-3">
                                                            <input type="text" 
                                                                className="form-control rounded-0 border-secondary"
                                                                 value={officeAddresses && officeAddresses.name ? officeAddresses.name : ""}
                                                                placeholder="First name" />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            <input type="text" 
                                                                className="form-control rounded-0 border-secondary"
                                                                 value={officeAddresses && officeAddresses.name ? officeAddresses.name : ""}
                                                                placeholder="Last name" />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            <input type="tel" 
                                                                className="form-control rounded-0 border-secondary"
                                                                value={officeAddresses && officeAddresses.mobile ? officeAddresses.mobile : ""}
                                                                placeholder="Phone number" />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            {/* <select id="country" className="form-select" >
                                                                <option value="">Select a country</option>
                                                                <option value="US">United States</option>
                                                                <option value="IN">India</option>
                                                                <option value="UK">United Kingdom</option>
                                                            </select> */}
                                                            <input type="tel" 
                                                                className="form-control rounded-0 border-secondary"
                                                                value={officeAddresses && officeAddresses.country ? officeAddresses.country : ""}
                                                                placeholder="Country Name" />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            <input type="text" 
                                                                className="form-control rounded-0 border-secondary"
                                                                value={officeAddresses && officeAddresses.address ? officeAddresses.address : ""}
                                                                placeholder="Address line1" />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            <input type="text"
                                                                className="form-control rounded-0 border-secondary" placeholder="City"
                                                                value={officeAddresses && officeAddresses.city ? officeAddresses.city : ""}
                                                                />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            <input type="text"
                                                                className="form-control rounded-0 border-secondary" placeholder="State"
                                                                value={officeAddresses && officeAddresses.state ? officeAddresses.state : ""}
                                                                />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            <input type="text" 
                                                                className="form-control rounded-0 border-secondary"
                                                                value={officeAddresses && officeAddresses.pincode ? officeAddresses.pincode : ""}
                                                                placeholder="Postcode/ZIP" />
                                                        </div>
                                                    </div>
                                                    <div className={`${selectedType === 'shipping' ? 'd-block' : 'd-none'}`}>
                                                    <div className="mb-md-4 mb-3">
                                                            <input type="text" 
                                                                className="form-control rounded-0 border-secondary"
                                                                 value={homeAddresses && homeAddresses.name ? homeAddresses.name : ""}
                                                                placeholder="First name" />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            <input type="text" 
                                                                className="form-control rounded-0 border-secondary"
                                                                 value={homeAddresses && homeAddresses.name ? homeAddresses.name : ""}
                                                                placeholder="Last name" />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            <input type="tel" 
                                                                className="form-control rounded-0 border-secondary"
                                                                value={homeAddresses && homeAddresses.mobile ? homeAddresses.mobile : ""}
                                                                placeholder="Phone number" />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            {/* <select id="country" className="form-select" >
                                                                <option value="">Select a country</option>
                                                                <option value="US">United States</option>
                                                                <option value="IN">India</option>
                                                                <option value="UK">United Kingdom</option>
                                                            </select> */}
                                                            <input type="tel" 
                                                                className="form-control rounded-0 border-secondary"
                                                                value={homeAddresses && homeAddresses.country ? homeAddresses.country : ""}
                                                                placeholder="Country Name" />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            <input type="text" 
                                                                className="form-control rounded-0 border-secondary"
                                                                value={homeAddresses && homeAddresses.address ? homeAddresses.address : ""}
                                                                placeholder="Address line1" />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            <input type="text"
                                                                className="form-control rounded-0 border-secondary" placeholder="City"
                                                                value={homeAddresses && homeAddresses.city ? homeAddresses.city : ""}
                                                                />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            <input type="text"
                                                                className="form-control rounded-0 border-secondary" placeholder="State"
                                                                value={homeAddresses && homeAddresses.state ? homeAddresses.state : ""}
                                                                />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            <input type="text" 
                                                                className="form-control rounded-0 border-secondary"
                                                                value={homeAddresses && homeAddresses.pincode ? homeAddresses.pincode : ""}
                                                                placeholder="Postcode/ZIP" />
                                                        </div>
                                                    </div>
                                                    <div className={`${selectedType === 'other' ? 'd-block' : 'd-none'}`}>
                                                        <div className="mb-md-4 mb-3">
                                                            <input type="text" 
                                                                className="form-control rounded-0 border-secondary"
                                                                 value={otherAddresses && otherAddresses.name ? otherAddresses.name : ""}
                                                                placeholder="First name" />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            <input type="text" 
                                                                className="form-control rounded-0 border-secondary"
                                                                 value={otherAddresses && otherAddresses.name ? otherAddresses.name : ""}
                                                                placeholder="Last name" />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            <input type="tel" 
                                                                className="form-control rounded-0 border-secondary"
                                                                value={otherAddresses && otherAddresses.mobile ? otherAddresses.mobile : ""}
                                                                placeholder="Phone number" />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            {/* <select id="country" className="form-select" >
                                                                <option value="">Select a country</option>
                                                                <option value="US">United States</option>
                                                                <option value="IN">India</option>
                                                                <option value="UK">United Kingdom</option>
                                                            </select> */}
                                                            <input type="tel" 
                                                                className="form-control rounded-0 border-secondary"
                                                                value={otherAddresses && otherAddresses.country ? otherAddresses.country : ""}
                                                                placeholder="Country Name" />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            <input type="text" 
                                                                className="form-control rounded-0 border-secondary"
                                                                value={otherAddresses && otherAddresses.address ? otherAddresses.address : ""}
                                                                placeholder="Address line1" />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            <input type="text"
                                                                className="form-control rounded-0 border-secondary" placeholder="City"
                                                                value={otherAddresses && otherAddresses.city ? otherAddresses.city : ""}
                                                                />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            <input type="text"
                                                                className="form-control rounded-0 border-secondary" placeholder="State"
                                                                value={otherAddresses && otherAddresses.state ? otherAddresses.state : ""}
                                                                />
                                                        </div>
                                                        <div className="mb-md-4 mb-3">
                                                            <input type="text" 
                                                                className="form-control rounded-0 border-secondary"
                                                                value={otherAddresses && otherAddresses.pincode ? otherAddresses.pincode : ""}
                                                                placeholder="Postcode/ZIP" />
                                                        </div>
                                                    </div>
                                                    <button type="submit"
                                                        className="bg-secondary text-white border-0 px-4 py-2 fw-bold">UPDATE DETAILS</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {activeContent === 'myWishlistContent' && 
                                <div id="myWishlistContent" className="content-section">
                                    <h2 className="mb-4">MY WISHLIST</h2>
                                    <hr/>
                                    <div className="wish-head pb-3 border-bottom-2">
                                        <div className="row">
                                            <div className="col-lg-6 col-5">
                                                <p className="mb-0">Product</p>
                                            </div>
                                            <div className="col-lg-2 col-2">
                                                <p className="mb-0">Price</p>
                                            </div>
                                            <div className="col-lg-2 col-3">
                                                <p className="mb-0">Quantity</p>
                                            </div>
                                            <div className="col-lg-2 col-2 d-flex justify-content-end">
                                                <p className="mb-0">Total</p>
                                            </div>
                                        </div>
                                    </div>
                                    {favItems &&
                                        favItems.map((item, index) => {
                                            const price = Number(item.min_max_price?.special_price || 0);
                                            const quantity = favQuantities[index] || 1;

                                            return (
                                                <div className="wish-content py-3 border-bottom-2" key={index}>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-5">
                                                            <div className="wish-details row align-items-center my-2">
                                                                <div className="col-12 col-lg-4 my-2">
                                                                    <img
                                                                        src={item.image}
                                                                        className="img-fluid w-100"
                                                                        alt="Purple Majesty"
                                                                    />
                                                                </div>
                                                                <div className="col-12 col-lg-8 ps-lg-4 my-2">
                                                                    <h6 className="fw-bold">{item.name}</h6>
                                                                    <p className="fst-italic mb-0">by {item.seller_name}</p>
                                                                    <div className="wish-ratings text-secondary">
                                                                        <span>
                                                                            {[...Array(5)].map((_, idx) => (
                                                                                <span
                                                                                    key={idx}
                                                                                    style={{
                                                                                        color:
                                                                                            idx < Math.round(Number(item.rating))
                                                                                                ? "gold"
                                                                                                : "lightgray",
                                                                                    }}
                                                                                >
                                                                                    ★
                                                                                </span>
                                                                            ))}
                                                                        </span>
                                                                        <span className="text-muted">
                                                                            ({item.no_of_ratings})
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-2 d-flex align-items-center">
                                                            <p className="mb-0">₹{price}</p>
                                                        </div>
                                                        <div className="col-lg-2 col-3 d-flex align-items-center">
                                                            <div>
                                                                <div className="d-flex align-items-center px-md-3 border-secondary">
                                                                    <button
                                                                        className="btn btn-outline-secondary decrement-btn border-0 p-1 py-1 fs-4"
                                                                        onClick={() => handleFavDecrement(index)}
                                                                    >
                                                                        -
                                                                    </button>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control text-center mx-1 quantity-input border-0"
                                                                        style={{ width: "40px" }}
                                                                        value={quantity}
                                                                        readOnly
                                                                    />
                                                                    <button
                                                                        className="btn btn-outline-secondary increment-btn border-0 p-1 py-1 fs-4"
                                                                        onClick={() => handleFavIncrement(index)}
                                                                    >
                                                                        +
                                                                    </button>
                                                                </div>
                                                                <p className="fs-8 mb-0 mt-3">
                                                                    Remove Item{" "}
                                                                    <span className="remove-wish ms-1 rounded-circle"  onClick={() => ClearFavItem(item.id)}>x</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-2 d-flex align-items-center justify-content-end">
                                                            <p className="mb-0">₹{price * quantity}</p>
                                                        </div>
                                                    </div>
                                                    <div className="text-end">
                                                        <button className="fs-8 border-0 text-white bg-secondary px-3 py-1 rounded-pill fw-bold" onClick={() => handleAddToCart(favQuantities[index], item.variants[0].id)}>
                                                            Add To Cart
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                    })}
                                    <div className="wish-last py-3">
                                        <div className="d-flex justify-content-between">
                                            <p className="fs-8 mb-0">Empty Wishlist<span
                                                    className="remove-wish ms-1 rounded-circle"  onClick={() => ClearAllFavItems()} >x</span></p>
                                            <p className="mb-0 fw-bold">
                                                Total  ₹{totalAmount.toFixed(2)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            }

                            {activeContent === 'myCartContent' && 
                                <div id="myCartContent" className="content-section">
                                    <h2 className="mb-4">MY CART</h2>
                                    <hr/>
                                    <div className="cart-head pb-3 border-bottom-2">
                                        <div className="row">
                                            <div className="col-lg-6 col-5">
                                                <p className="mb-0">Product</p>
                                            </div>
                                            <div className="col-lg-2 col-2">
                                                <p className="mb-0">Price</p>
                                            </div>
                                            <div className="col-lg-2 col-3">
                                                <p className="mb-0">Quantity</p>
                                            </div>
                                            <div className="col-lg-2 col-2 d-flex justify-content-end">
                                                <p className="mb-0">Total</p>
                                            </div>
                                        </div>
                                    </div>
                                    {cartItems &&
                                        cartItems.map((item, index) => {
                                            const price = (item.price || 0);
                                            const quantity = cartQuantities[index] || 1;

                                            return (
                                                <div className="wish-content py-3 border-bottom-2" key={index}>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-5">
                                                            <div className="wish-details row align-items-center my-2">
                                                                <div className="col-12 col-lg-4 my-2">
                                                                    <img
                                                                        src={item.image}
                                                                        className="img-fluid w-100"
                                                                        alt="Purple Majesty"
                                                                    />
                                                                </div>
                                                                <div className="col-12 col-lg-8 ps-lg-4 my-2">
                                                                    <h6 className="fw-bold">{item.name}</h6>
                                                                    <p className="fst-italic mb-0">by {item.seller_name}</p>
                                                                    <div className="wish-ratings text-secondary">
                                                                        <span>
                                                                            {[...Array(5)].map((_, idx) => (
                                                                                <span
                                                                                    key={idx}
                                                                                    style={{
                                                                                        color:
                                                                                            idx < Math.round(Number(item.rating))
                                                                                                ? "gold"
                                                                                                : "lightgray",
                                                                                    }}
                                                                                >
                                                                                    ★
                                                                                </span>
                                                                            ))}
                                                                        </span>
                                                                        <span className="text-muted">
                                                                            ({item.no_of_ratings})
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-2 d-flex align-items-center">
                                                            <p className="mb-0">₹{price}</p>
                                                        </div>
                                                        <div className="col-lg-2 col-3 d-flex align-items-center">
                                                            <div>
                                                                <div className="d-flex align-items-center px-md-3 border-secondary">
                                                                    <button
                                                                        className="btn btn-outline-secondary decrement-btn border-0 p-1 py-1 fs-4"
                                                                        onClick={() => handleCartDecrement(index)}
                                                                    >
                                                                        -
                                                                    </button>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control text-center mx-1 quantity-input border-0"
                                                                        style={{ width: "40px" }}
                                                                        // value={quantity}
                                                                        value={quantity}
                                                                        readOnly
                                                                    />
                                                                    <button
                                                                        className="btn btn-outline-secondary increment-btn border-0 p-1 py-1 fs-4"
                                                                        onClick={() => handleCartIncrement(index)}
                                                                    >
                                                                        +
                                                                    </button>
                                                                </div>
                                                                <p className="fs-8 mb-0 mt-3">
                                                                    Remove Item{" "}
                                                                    <span className="remove-wish ms-1 rounded-circle"  onClick={() => ClearCartItem(item.id)}>x</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-2 d-flex align-items-center justify-content-end">
                                                            <p className="mb-0">₹{price * quantity}</p>
                                                        </div>
                                                    </div>
                                                    {/* <div className="text-end">
                                                        <button className="fs-8 border-0 text-white bg-secondary px-3 py-1 rounded-pill fw-bold">
                                                            Add To Cart
                                                        </button>
                                                    </div> */}
                                                </div>
                                            );
                                    })}
                                    <div className="wish-last py-3">
                                        <div className="d-flex justify-content-between">
                                            <p className="fs-8 mb-0">Empty Cart <span
                                                    className="remove-wish ms-1 rounded-circle"  onClick={() => ClearAllCartItems()} >x</span>
                                            </p>
                                            <p className="mb-0 fw-bold">
                                                Total  ₹{cartTotalAmount.toFixed(2)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="text-end my-3">
                                        <button className="border-0 bg-secondary text-white px-4 py-2 fw-bold" onClick={handleCheckout}>PROCEED TO CHECKOUT</button>
                                    </div>
                                </div>
                            }

                            {activeContent === 'myWalletContent' && 
                                <div id="myWalletContent" className="content-section">
                                    <h2 className="mb-4">MY TRANSACTIONS</h2>
                                    <hr/>
                                    <div className="container my-4">
                                        {/* <ul className="nav nav-tabs" id="transactionTabs" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link wallet-credit active" id="credit-tab"
                                                    data-bs-toggle="tab" data-bs-target="#credit" type="button"
                                                    role="tab">Credit</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link wallet-credit" id="debit-tab" data-bs-toggle="tab"
                                                    data-bs-target="#debit" type="button" role="tab">Debit</button>
                                            </li>
                                        </ul> */}

                                        <div className="tab-content p-0" id="transactionTabsContent">
                                            <div className="tab-pane fade show active" id="credit" role="tabpanel">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th>S.No</th>
                                                                <th>Order Id</th>
                                                                <th>Date</th>
                                                                <th>Amount</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {transactions && transactions.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.order_id}</td>
                                                                    <td>{formatDate(item.transaction_date)}</td>
                                                                    <td >{item.amount}</td>
                                                                    <td>{item.status}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {/* 
                                                <div className="tab-pane fade" id="debit" role="tabpanel">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th>Date</th>
                                                                    <th>Time</th>
                                                                    <th>Description</th>
                                                                    <th>Expiry Code</th>
                                                                    <th>Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>10/02/2024</td>
                                                                    <td>9:00 am</td>
                                                                    <td>Description</td>
                                                                    <td>10/02/2025</td>
                                                                    <td>₹1998</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>10/02/2024</td>
                                                                    <td>9:00 am</td>
                                                                    <td>Description</td>
                                                                    <td>10/02/2025</td>
                                                                    <td>₹1998</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>10/02/2024</td>
                                                                    <td>9:00 am</td>
                                                                    <td>Description</td>
                                                                    <td>10/02/2025</td>
                                                                    <td>₹1998</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div> 
                                            */}

                                        </div>
                                    </div>

                                </div>
                            }      

                            {activeContent === 'myOrdersContent' && 
                                <div id="myOrdersContent" className="content-section">
                                    <h2 className="mb-4">MY ORDERS</h2>
                                    <div className="wish-content py-3 border-bottom-2">
                                        <div className={`row ${isViewOrder ? 'd-none' : 'd-block'}`}>
                                            {myOrders &&
                                                myOrders.map((item, index) => {
                                                    return (
                                                        <div className="card mb-1" key={index}>
                                                            <div className="card-body">
                                                                <div className="d-flex justify-contnet-between">
                                                                    <div>
                                                                        <p className="text-muted">Order ID: <span className="fw-bold text-dark">{item.id}</span> </p>
                                                                        <p className="text-muted">Plcaed On: <span className="fw-bold text-dark">{item.date_added}</span> </p>
                                                                    </div>
                                                                    <div className="ms-auto">
                                                                        <button className="btn border me-3" onClick={() => handleViewClick(item)}>View Details</button>
                                                                        <button className="btn btn-primary">Reorder</button>
                                                                    </div>
                                                                </div>
                                                                <hr/>
                                                                <div className="media d-flex flex-sm-row mb-4">
                                                                    {item.order_items.length > 1 ? 
                                                                        item.order_items.map((prod, indx) => (
                                                                            <div className="d-flex flex-column"  key={indx}>
                                                                                <div className="media-body">
                                                                                    <Link to={`/product-details/${prod.product_id}`}
                                                                                        className="text-decoration-none text-dark fw-bold">
                                                                                        <h5 className="bold">{indx + 1}. {prod.name}</h5>
                                                                                    </Link>
                                                                                    <p className="text-muted mb-0"> Quantity : {prod.quantity}</p>
                                                                                    {Array.from({ length: 5 }, (_, index) => (
                                                                                        <span
                                                                                            key={index}
                                                                                            style={{
                                                                                                color: index < Math.floor(prod.user_rating) ? "gold" : "gray",
                                                                                            }}
                                                                                        >
                                                                                            ★
                                                                                        </span>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    : 
                                                                    <div className="media-body ">
                                                                        <Link to={`/product-details/${item.order_items[0].product_id}`}
                                                                            className="text-decoration-none text-dark fw-bold">
                                                                            <h5 className="bold">{index + 1}. {item.name}</h5>
                                                                        </Link>
                                                                        <p className="text-muted mb-0"> Quantity : {item.order_items[0].quantity}</p>
                                                                        {Array.from({ length: 5 }, (_, index) => (
                                                                            <span
                                                                                key={index}
                                                                                style={{
                                                                                    color: index < Math.floor(item.order_items[0].user_rating) ? "gold" : "gray",
                                                                                }}
                                                                            >
                                                                                ★
                                                                            </span>
                                                                        ))}
                                                                        <h4 className="mt-3 mb-2 bold"> <span className="mt-5"><i>₹</i></span> {item.order_items[0].special_price} 
                                                                            <span className="text-danger mx-2 text-decoration-line-through">{item.order_items[0].main_price}</span>
                                                                            <span className="small text-muted ms-2 fs-6">via ({item.payment_method})</span>
                                                                        </h4>
                                                                    </div> }
                                                                    <div className="align-self-center img-fluid ms-auto">
                                                                        <Link to={`/product-details/${item.order_items[0].product_id}`}>
                                                                            <img src={item.order_items[0].image} width="180 "
                                                                                height="180" className="logo-fit rounded"/>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                            })}
                                        </div>

                                        <div className={`row ${isViewOrder ? 'd-block' : 'd-none'}`}>
                                            <div className="col-md-12 orders-section settings-tab-content">
                                                <div className="mb-4 border-0 shadow-xl p-10">
                                                    <div className="card-header bg-white">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="col">
                                                                <p className="text-muted"> Order ID<span className="font-weight-bold text-dark"> : {viewOrderDetails && viewOrderDetails.id}</span></p>
                                                                <p className="text-muted"> Place On<span className="font-weight-bold text-dark"> : {viewOrderDetails && viewOrderDetails.date_added}</span> </p>
                                                            </div>

                                                            <div className="flex-col my-auto">
                                                                <h6 className="ml-auto mr-3">
                                                                    <a className="btn btn-xs btn-outline-primary me-3" onClick={printInvoice}>Invoice</a>
                                                                   <button
                                                                        onClick={() => setIsViewOrder(false)}
                                                                        className="btn btn-xs btn-outline-danger"
                                                                    >
                                                                        Back to List
                                                                    </button>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div className="row">
                                                            <form onSubmit={(event) => handleSubmit(event, viewOrderDetails && viewOrderDetails.id)}>
                                                                <div className="form-group">
                                                                    <label htmlFor="receipt">
                                                                        <strong>Bank Payment Receipt</strong>
                                                                    </label>
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        name="attachments[]"
                                                                        id="receipt"
                                                                        multiple
                                                                        onChange={handleFileChange}
                                                                    />
                                                                </div>
                                                                <div className="form-group mt-2">
                                                                    <button type="reset" className="btn btn-outline-warning btn-xs">Reset</button>
                                                                    <button type="submit" className="ms-2 btn btn-outline-success btn-xs" disabled={loading}>
                                                                        {loading ? "Sending..." : "Send"}
                                                                    </button>
                                                                </div>
                                                                {error && <div className="alert alert-danger mt-2">{error}</div>}
                                                                {success && <div className="alert alert-success mt-2">Proof sent successfully!</div>}
                                                            </form>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                {viewOrderDetails?.attachments?.length > 0 ? (
                                                                    viewOrderDetails.attachments.map((item, index) => (
                                                                        <div key={index}>
                                                                            <small>
                                                                                [<a href={item.attachment} target="_blank" rel="noopener noreferrer">
                                                                                    Attachment {index + 1}
                                                                                </a>]
                                                                            </small>
                                                                            <label className="badge badge-primary">Accepted</label>
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <p>No attachments available.</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        {viewOrderDetails?.order_items?.length > 0 ? (
                                                            viewOrderDetails.order_items.map((item, index) => (
                                                                <div key={index}>
                                                                    <div className="media d-flex flex-sm-row mb-2">
                                                                        <div className="media-body">
                                                                            <Link to={`/product-details/${item.product_id}`} className="text-decoration-none">
                                                                                <h5 className="bold">{index + 1}. {item.name}</h5>
                                                                            </Link>
                                                                            <p className="text-muted mb-0">Quantity: {item.quantity}</p>
                                                                            <h4 className="mt-3 mb-2 bold">
                                                                                <span className="mt-5"><i>₹</i></span> {item.price}
                                                                                <span className="text-danger mx-2 text-decoration-line-through">{item.main_price}</span>
                                                                            </h4>
                                                                        </div>
                                                                        <div className="align-self-center img-fluid ms-auto">
                                                                            <Link to={`/product-details/${item.product_id}`}>
                                                                            <img
                                                                                src={item.image}
                                                                                width="180"
                                                                                height="180"
                                                                                className="logo-fit rounded"
                                                                            />
                                                                            </Link>
                                                                        </div>
                                                                    </div>

                                                                    <section className="wrapper bg-light mb-2">
                                                                        <div className="container py-8">
                                                                            <div className="row gx-lg-8 gx-xl-12 gy-6 process-wrapper line" id="progressbar">
                                                                            {item.status?.length > 0 ? (
                                                                                item.status.map((statusItem, statusIndex) => (
                                                                                <div className={`col-md-6 col-lg-2 ${statusIndex === 0 ? "active" : ""}`} key={statusIndex}>
                                                                                    <span className="icon btn btn-circle btn-secondary pe-none mb-2">
                                                                                        <span className="number" id={`step${statusIndex + 1}`}>{statusIndex + 1}</span>
                                                                                    </span>
                                                                                    <h6 className="mb-0 texxt-uppercase" style={{ textTransform: 'uppercase' }}>{statusItem[0]}</h6>
                                                                                    <p className="mb-0">{statusItem[1]}</p>
                                                                                </div>
                                                                                ))
                                                                            ) : (
                                                                                <p>No status available</p>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <p>No order items available.</p>
                                                        )}

                                                        <div className="row mt-3">
                                                            <div className="col-md-6">
                                                                <h6 className="h5">Shipping Details</h6>
                                                                <hr className="" />
                                                                <span>{viewOrderDetails && viewOrderDetails.username}</span> <br/>
                                                                <span>{viewOrderDetails && viewOrderDetails.address}</span> <br/>
                                                                <span>{viewOrderDetails && viewOrderDetails.mobile}</span> <br/>
                                                                <span>{viewOrderDetails && viewOrderDetails.delivery_time}</span> <br/>
                                                                <span>{viewOrderDetails && viewOrderDetails.delivery_date}</span> <br/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <h6 className="h5">Price Details</h6>
                                                                <hr className="" />
                                                                <div className="table-responsive">
                                                                    <table className="table table-borderless">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>Total Order Price</th>
                                                                                <td>+ ₹ {viewOrderDetails && viewOrderDetails.total}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Delivery Charge</th>
                                                                                <td>+ ₹ {viewOrderDetails && viewOrderDetails.delivery_charge}</td>
                                                                            </tr>
                                                                            <tr className="d-none">
                                                                                <th>Tax - (0%)</th>
                                                                                <td>+ ₹ {viewOrderDetails && viewOrderDetails.total_tax_amount}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Wallet Used</th>
                                                                                <td>- ₹ {viewOrderDetails && viewOrderDetails.wallet_balance}</td>
                                                                            </tr>
                                                                            <tr className="h6">
                                                                                <th>Final Total</th>
                                                                                <td>₹ {viewOrderDetails && viewOrderDetails.final_total}<span className="small text-muted"> via ({viewOrderDetails && viewOrderDetails.payment_method}) </span></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* {activeContent === 'savedCardsContent' && 
                                <div id="savedCardsContent" className="content-section">
                                    <h2 className="mb-4">MY SAVED CARDS</h2>
                                    <hr/>
                                    <p>Manage your saved credit/debit cards for quick payments.</p>
                                </div>
                            } */}

                            {/* {activeContent === 'signOutContent' && 
                                <div id="signOutContent" className="content-section">
                                    <h2 className="mb-4">SIGN OUT</h2>
                                    <hr/>
                                    <p>You have successfully signed out. Please log in again to access your account.</p>
                                </div>
                            } */}
                        </div>
                    </div>
                </div>
            </div>
            
        </Base>
  
    )
}