import React, { useEffect } from "react";
import Base from "../Config/Base";
import ThankYouImg from './tq.png';
import ThankYouImg1 from './tq1.jpg';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';


export default function ThankYouForOrder () {

    useEffect(() => {
            window.scrollTo(0, 0);
    }, []);

    const { OrderId } = useParams();

    return (
        <Base>
            <div className="container-fluid row d-flex justify-content-center">
                <img src={ThankYouImg} style={{ width: '30%' }} />
                {/* <img src={ThankYouImg1} style={{ width: '30%' }} /> */}
                <p className="text-center">Thank you for placing your order <span className="fw-bold">(ORD-{OrderId})</span></p>
            </div>
            <Link to='/' className="text-decoration-none">
                <button className="btn bg-secondary text-white fw-bold d-flex m-auto my-3"><i className="fa-solid fa-reply me-1 mt-1"></i>Return to Home</button>
            </Link>
        </Base>
    )
}