import React, { useEffect, useState } from "react";
import Base from "../Config/Base";

export default function PrivacyPolicy () {
    
    useEffect(() => {
            window.scrollTo(0, 0);
    }, []);

    const [settingsData, setSettingsData] = useState([]);

    useEffect(() => {
        const settings = JSON.parse(sessionStorage.getItem('settingsData'));
        setSettingsData(settings.privacy_policy[0]);
    })

    return (
        <Base>
            <div
                className="container"
                dangerouslySetInnerHTML={{ __html: settingsData }}
            ></div>
        </Base>
    )
}