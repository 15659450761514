import React, { useState, useEffect } from "react";
import Base from "../Config/Base";
import { BASE_API_URL } from "../Config/Config";
import axios from "axios";
import Select from 'react-select';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';


export default function BillingDetails () {

    useEffect(() => {
            window.scrollTo(0, 0);
    }, []);

    const [userData, setUserData] = useState([]);
    const [address, setAddress] = useState([]);
    const [displayAddress, setDisplayAddress] = useState([]);
    const [width, setWidth] = useState("50%");
    const [cartItems, setCartItems] = useState([]);
    const [citiesDDL, setCitiesDDL] = useState([]);
    const [zipcodesDDL, setZipcodesDDL] = useState([]);
    const [deliveryStatus, setDeliveryStatus] = useState({});
    const [deliveryStatusClass, setDeliveryStatusClass] = useState('');
    const [preferredDeliveryDate, setPreferredDeliveryDate] = useState(null);
    const [cartVariants, setCartVariants] = useState([]);
    const [cartTotalData, setCartTotalData] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [activeStatus, setActiveStatus] = useState(null);
    // const [selectedCityOption, setSelectedCityOption] = useState(null);
    
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [altMobile, setAltMobile] = useState('');
    const [addressFiled, setAddressFiled] = useState('');
    const [city, setCity] = useState(null);
    const [area, setArea] = useState('');
    const [pincode, setPincode] = useState(null);
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');      
    const [isChecked, setIsChecked] = useState(false);
    const [isOther, setIsOther] = useState(false);
    const [customPincode, setCustomPincode] = useState("");

    useEffect(() => {
        const storedUserData = sessionStorage.getItem('UserData');
        if (storedUserData) {
            try {
                setUserData(JSON.parse(storedUserData));
            } catch (error) {
                console.error("Error parsing storedUserData:", error);
            }
        }
    }, []);

    useEffect(() => {
        fetchCartItems();
    }, [userData]);
    
    const fetchCartItems = async () => {
        try {
            const formData = new FormData();
            formData.append("user_id", userData.id);

            const response = await axios.post(`${BASE_API_URL}get_user_cart`, formData, {
                headers: {
                    Authorization: `Bearer ${userData.apikey}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            setCartItems(response.data.data);
            setCartVariants(response.data.variant_id);
            setCartTotalData(response.data);
            console.log(response.data);
        } catch (err) {
            console.log('failed fetching fav items..!');
        }
    };

    const fetchCities = async () => {
        try {
            const response = await axios.post(`${BASE_API_URL}get_cities`, {}, {});
            setCitiesDDL(response.data.data);
            const responseZipcodes = await axios.post(`${BASE_API_URL}get_zipcodes`, {}, {});
            setZipcodesDDL(responseZipcodes.data.data);
        } catch (err) {
            console.log('failed fetching cities..!');
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.matchMedia("(max-width: 768px)").matches) {
                setWidth("100%");
            } else {
                setWidth("50%");
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const fetchAddress = async () => {
        try {
            const formData = new FormData();
            formData.append("user_id", userData.id);

            const response = await axios.post(`${BASE_API_URL}get_address`, formData, {
                headers: {
                    Authorization: `Bearer ${userData?.apikey}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            setAddress(response.data.data);
        } catch (err) {
            console.log('failed fetching fav items..!');
        }
    };

    useEffect(() => {
        if (Array.isArray(address) && address.length > 0) {
            setDisplayAddress(address[0]);
        }
    }, [address]);
    
    useEffect(() => {
        fetchAddress();
        fetchCities();
    }, [userData]);

    const checkProductDelivery = async (addressPincode) => {
        try {
            const updatedStatus = {};
            const updatedStatusClass = {};
    
            for (let item of cartItems) {
                const formData = new FormData();
                formData.append("product_id", item.id);
                formData.append("zipcode", addressPincode);
    
                const response = await axios.post(`${BASE_API_URL}is_product_delivarable`, formData, {
                    headers: {
                        Authorization: `${userData.apikey}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
    
                const statusMessage = response.data.error ? response.data.message : "Product available for delivery";
                updatedStatus[item.id] = statusMessage;
    
                if (response.data.error) {
                    updatedStatusClass[item.id] = "text-danger";
                } else {
                    updatedStatusClass[item.id] = "text-success";
                }
            }
    
            setDeliveryStatus(updatedStatus);
            setDeliveryStatusClass(updatedStatusClass);
        } catch (err) {
            console.log('Failed fetching delivery status:', err);
        }
    };
    
    useEffect(() => {
        if (displayAddress && displayAddress.pincode) {
            const pincode = displayAddress.pincode;
            // console.log(pincode, displayAddress);
            checkProductDelivery(pincode); 
        }
        // console.log(userData)
    }, [displayAddress, cartItems]);

    const [selectedTimeSlot, setSelectedTimeSlot] = useState("");

    const handleTimeSlotChange = (event) => {
        setSelectedTimeSlot(event.target.value);
        setErrorMessage(null);
    };

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

    const handlePaymentMethodChange = (event) => {
        const selectedMethod = event.target.value; // Capture the value directly
        setSelectedPaymentMethod(selectedMethod);
        console.log("Selected Payment Method:", selectedMethod);
    
        setErrorMessage(null);
    
        if (selectedMethod === 'Razorpay' || selectedMethod === 'Paytm' || selectedMethod === 'COD') {
            setActiveStatus('received');
            // console.log("Status to be set: received");
        } else if (selectedMethod === 'bank_transfer') {
            setActiveStatus('awaiting');
            // console.log("Status to be set: awaiting");
        }
    };

    const navigate = useNavigate();

    const placeOrder = async () => {

        if (!preferredDeliveryDate) {
            alert('prefered date is mandatory.')
            return;
        }
        
        const formData = new FormData();

        const variantIds = cartVariants.join(',');
    
        const quantities = cartItems.map((item) => item.qty).join(',');
    
        formData.append("mobile", userData.mobile);
        formData.append("email", userData.email);
        formData.append("product_variant_id", variantIds);
        formData.append("quantity", quantities);
        formData.append("total", cartTotalData.total_arr);
        formData.append("delivery_charge", cartTotalData.delivery_charge);
        formData.append("tax_amount", cartTotalData.tax_amount);
        formData.append("tax_percentage", cartTotalData.tax_percentage);
        formData.append("user_id", userData.id);
        formData.append("final_total", finalBalance);
        formData.append("payment_method", selectedPaymentMethod);
        formData.append("address_id", displayAddress.id);
        formData.append("delivery_date", preferredDeliveryDate);
        formData.append("delivery_time", selectedTimeSlot);
        formData.append("wallet_balance_used", userData.balance);
        formData.append("is_wallet_used", isChecked ? 1 : 0);
        formData.append("active_status", activeStatus);

        for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }
    
        try {
            const response = await axios.post(`${BASE_API_URL}place_order`, formData, {
                headers: {
                    Authorization: `Bearer ${userData.apikey}`,
                    "Content-Type": "multipart/form-data",
                },
            });
    
            console.log("order data from api:", response.data);

            if (!response.data.error) {
                alert("Order placed successfully!");
                navigate(`/thank-you/${response.data.order_id}`);
            } else {
                console.error("Failed to place order:", response.statusText);
                // alert("Failed to place order.");
                setErrorMessage(response.data.message)
            }
        } catch (error) {
            console.error("Error placing order:", error);
            alert("An error occurred while placing the order.");
        }
    };

    const handleSelectChange = (selectedOption) => {
        if (selectedOption.value === "other") {
            setIsOther(true);
            setPincode({ value: "other", label: "Other" });
        } else {
            setIsOther(false);
            setPincode(selectedOption);
        }
    };
        
    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
        console.log("Checkbox checked:", e.target.checked);
    };
    
    const finalBalance = isChecked 
    ? cartTotalData.total_arr - userData.balance
    : cartTotalData.total_arr;

    const handleSave = async () => {
        const formData = new FormData();
    
        formData.append('name', name);
        formData.append('mobile', mobile);
        formData.append('alternate_mobile', altMobile);
        formData.append('address', addressFiled);
        formData.append('city', city?.value);
        formData.append('area', area);
        // formData.append('pincode', pincode?.value);
        formData.append("pincode", isOther ? customPincode : pincode?.value);
        formData.append('state', state);
        formData.append('country', country);
    
        // const selectedTypes = [];
        // if (document.getElementById('Home').checked) selectedTypes.push('Home');
        // if (document.getElementById('Office').checked) selectedTypes.push('Office');
        // if (document.getElementById('Other').checked) selectedTypes.push('Other');
        formData.append('types', selectedOptionType);

        for (const [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }
    
        try {
            const response = await axios.post(
                `${BASE_API_URL}add_address`, formData,
                {
                    headers: {
                        Authorization: `Bearer ${userData?.apikey}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (!response.data.error) {
                Swal.fire({
                    title: 'Address Saved Successfully!',
                    text: 'Your address has been saved.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                }).then(() => {
                    window.location.reload();
                });
            } else {
                Swal.fire({
                    title: 'Error Saving Address',
                    text: response.message,
                    icon: 'error',
                    confirmButtonText: 'Retry',
                });
            }
    
        } catch (error) {
            Swal.fire({
                title: 'Error Saving Address',
                text: 'There was an issue saving your address. Please try again.',
                icon: 'error',
                confirmButtonText: 'Retry',
            });
        }
    };

    const [selectedOptionType, setSelectedOptionType] = useState("");

    const handleCheckboxChangeType = (option) => {
        setSelectedOptionType(option);
    };
      
    return (
        <Base>
            <div className="container">
                <div className="row gx-md-8 gx-xl-12 gy-12">
                    <div className="col-lg-8">
                        <h3 className="checkout-form-title">
                            Billing Details </h3>
                        <hr className="mt-7 mb-6"/>
                        <div className="bg-white mt-5">
                            <div className="ship-details-wrapper address-details">
                                <div className="align-item-center ship-title-details justify-content-between user-add d-flex">
                                    <h5 className="pb-3">Shipping Address</h5>
                                    <a className="float-end text-decoration-none" 
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasRightAddress" 
                                        aria-controls="offcanvasRightAddress"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <i className="fa-regular fa-pen-to-square text-secondary"></i>
                                    </a>

                                </div>
                                <hr className="mt-1 mb-2"/>

                                <div className="shipped-details mt-3">
                                    <p className="text-muted m-0" id="address-name-type">{displayAddress?.name} - {displayAddress?.type}</p>
                                    <p className="text-muted m-0" id="address-full">{displayAddress?.address}</p>
                                    <p className="text-muted m-0" id="address-country">{displayAddress?.state} , {displayAddress?.country} - {displayAddress?.pincode}</p>
                                    <p className="text-muted m-0" id="address-mobile">{displayAddress?.mobile}</p>
                                </div>

                                <br/>
                                {errorMessage && 
                                    <div id="deliverable_status"><b className="text-danger">{errorMessage}</b></div>
                                }

                            </div>
                            <hr className="mt-4 mb-4"/>
                        
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Special Note for Order" name="order_note"
                                    id="order_note"/>
                            </div>
                            <hr className="mt-4 mb-4"/>
                            <h4 className="mt-3">Preferred Delivery Date / Time</h4>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" style={{ height: '2.4rem' }}><i className="fa fa-clock"></i></span>
                                </div>
                                <input type="date" 
                                    className="form-control" 
                                    value={preferredDeliveryDate}
                                    onChange={(e) => setPreferredDeliveryDate(e.target.value)}                                    
                                />
                            </div>
                            <div className="mt-3" id="time_slots">
                                <div className="custom-control custom-radio">
                                    <input
                                        id="1"
                                        name="delivery_time"
                                        type="radio"
                                        className="time-slot-inputs form-check-input"
                                        data-last_order_time="10:00:00"
                                        value="Morning"
                                        onChange={handleTimeSlotChange}
                                    />
                                    <label className="form-check-label ms-1" htmlFor="1">Morning</label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input
                                        id="2"
                                        name="delivery_time"
                                        type="radio"
                                        className="time-slot-inputs form-check-input"
                                        data-last_order_time="15:30:00"
                                        value="Afternoon"
                                        onChange={handleTimeSlotChange}
                                    />
                                    <label className="form-check-label ms-1" htmlFor="2">Afternoon</label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input
                                        id="3"
                                        name="delivery_time"
                                        type="radio"
                                        className="time-slot-inputs form-check-input"
                                        data-last_order_time="05:00:00"
                                        value="Night"
                                        onChange={handleTimeSlotChange}
                                    />
                                    <label className="form-check-label ms-1" htmlFor="3">Night</label>
                                </div>
                                <div className="mt-3">
                                    <strong>Selected Time Slot:</strong> {selectedTimeSlot || "None"}
                                </div>
                            </div>
                            <hr className="mt-4 mb-4"/>
                            <input type="hidden" name="delivery_date" id="delivery_date" className="form-control float-right"/>
                            <div className="align-item-center ship-title-details justify-content-between d-flex">
                                <h5>Use wallet balance</h5>
                            </div>
                            <div className="form-check d-flex">
                                <input 
                                    className="form-check-input" 
                                    type="checkbox" 
                                    value="" 
                                    id="wallet_balance" 
                                    // disabled={true} 
                                    checked={isChecked} 
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label d-flex ms-1" for="wallet_balance">
                                    Available balance : ₹<span id="available_balance">{isChecked ? 0 : userData.balance}</span> </label>
                            </div>

                            <div className="ship-details-wrapper mt-3 payment-methods">
                                <div className="align-item-center ship-title-details justify-content-between d-flex">
                                    <h5>Payment Method</h5>
                                </div>
                                <div className="shipped-details mt-3 col-md-6">
                                    <label for="cod">
                                    </label>
                                    <table className="table table-step-shipping">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <label htmlFor="cod">
                                                        <input
                                                            id="cod"
                                                            className="form-check-input"
                                                            name="payment_method"
                                                            type="radio"
                                                            value="COD"
                                                            onChange={handlePaymentMethodChange}
                                                        />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label htmlFor="cod">
                                                        <img
                                                            loading="lazy"
                                                            src="https://shop.giivv.com/assets/front_end/modern/img/payments/cod.png"
                                                            style={{ width: '1rem' }}
                                                            className="payment-gateway-images"
                                                            alt="COD"
                                                        />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label htmlFor="cod">Cash On Delivery</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label htmlFor="razorpay">
                                                        <input
                                                            id="razorpay"
                                                            className="form-check-input"
                                                            name="payment_method"
                                                            type="radio"
                                                            value="Razorpay"
                                                            onChange={handlePaymentMethodChange}
                                                        />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label htmlFor="razorpay">
                                                        <img
                                                            loading="lazy"
                                                            src="https://shop.giivv.com/assets/front_end/modern/img/payments/razorpay.png"
                                                            style={{ width: '1rem' }}
                                                            className="payment-gateway-images"
                                                            alt="Razorpay"
                                                        />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label htmlFor="razorpay">RazorPay</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label htmlFor="paytm">
                                                        <input
                                                            id="paytm"
                                                            className="form-check-input"
                                                            name="payment_method"
                                                            type="radio"
                                                            value="Paytm"
                                                            onChange={handlePaymentMethodChange}
                                                        />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label htmlFor="paytm">
                                                        <img
                                                            loading="lazy"
                                                            src="https://shop.giivv.com/assets/front_end/modern/img/payments/paytm.png"
                                                            style={{ width: '1rem' }}
                                                            className="payment-gateway-images"
                                                            alt="Paytm"
                                                        />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label htmlFor="paytm">Paytm</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label htmlFor="bank_transfer">
                                                        <input
                                                            id="bank_transfer"
                                                            className="form-check-input"
                                                            name="payment_method"
                                                            type="radio"
                                                            value="bank_transfer"
                                                            onChange={handlePaymentMethodChange}
                                                        />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label htmlFor="bank_transfer">
                                                        <img
                                                            loading="lazy"
                                                            src="https://shop.giivv.com/assets/front_end/modern/img/payments/bank_transfer_!.png"
                                                            style={{ width: '1rem' }}
                                                            className="payment-gateway-images"
                                                            alt="Direct Bank Transfers"
                                                        />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label htmlFor="bank_transfer">Direct Bank Transfers</label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div id="stripe_div">
                                <div id="stripe-card-element">
                                </div>
                                <p id="card-error" role="alert"></p>
                                <p className="result-message hidden"></p>
                            </div>

                            <div id="my_fatoorah_div">
                                <div id="card-element">
                                </div>
                                <p id="card-error" role="alert"></p>
                                <p className="result-message hidden"></p>
                            </div>


                            <div id="bank_transfer_slide">
                                <div className="row">
                                    <div className="alert alert-warning">
                                        <strong>Edit Address </strong>
                                    </div>
                                    <div className="alert alert-info col-md-12">
                                        <strong>Account Details! </strong> <br/><br/>
                                        <ul>
                                            <li>Account Name: Giivv.Com E-Commerce LLC.</li>
                                            <li>Account Number: 020211022000001</li>
                                            <li>Bank Name: State Bank of India</li>
                                            <li>Bank Code: SBIIN0007</li>
                                        </ul>
                                    </div>
                                    <div className="alert alert-info col-md-12">
                                        <strong>Extra Details! </strong> <br/><br/>
                                        <p></p>
                                        <p>Please do not forget to upload the bank transfer receipt upon sending / depositing money
                                            to the above-mentioned account. Once the amount deposit is confirmed the order will be
                                            processed further. To upload the receipt go to your order details page or screen and
                                            find a form to upload the receipt.</p>
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                            <hr className="mb-2 mt-2"/>
                        </div>
                    </div>
                    <div className="col-xl-4 mt-5">
                        <div className="checkout-order-wrapper">
                            <div className="checkout-title">
                                <h1>Order Summary</h1>
                            </div>
                            <div className="order-details">
                                <div className="product-checkout-wrapper">
                                    <div className="product-checkout-title">
                                        <h5 className="clearfix mb-0 text-muted fs-16">
                                            <a>{cartItems && cartItems.length} Item(s) in Cart</a>
                                        </h5>
                                    </div>
                                    <div>
                                        <div className="product-checkout mt-4">
                                            <div className="shopping-cart-item justify-content-between mb-4 gap-4">
                                            {cartItems && cartItems.map((item, index) => {
                                                const deliveryMessage = deliveryStatus[item.id] || "Checking delivery...";
                                                const deliveryClass = deliveryStatusClass[item.id] || "text-warning";
                                                return (
                                                    <div className="d-flex align-items-center gap-4" key={index}>
                                                        <figure className="rounded checkout-img">
                                                            <a>
                                                                <img 
                                                                    loading="lazy"
                                                                    src={item.image} 
                                                                    style={{ width: '5rem' }}
                                                                    alt="" 
                                                                    className="image-fit rounded"
                                                                />
                                                            </a>
                                                        </figure>

                                                        <div className="w-100">
                                                            <h6 className="post-title fs-15 lh-xs mb-1 fw-bold">
                                                                <a className="link-dark text-decoration-none">
                                                                    {item.name}
                                                                </a>
                                                            </h6>
                                                            <div className="small">
                                                                <div key={item.id} className={deliveryClass}>
                                                                    <b>{deliveryMessage}</b>
                                                                </div>
                                                                <div className="qty">
                                                                    <span className="text-muted">Qty :</span>
                                                                    <span className="text-muted">{item.qty || 0}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                 );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cart-total-price">
                                        <table className="table cart-products-table">
                                            <tbody>
                                                <tr>
                                                    <td><strong className="text-dark">Subtotal</strong></td>
                                                    <td className="text-end">
                                                        <p className="price d-flex gap-1">₹ <span className="sub_total">{cartTotalData && cartTotalData.sub_total}</span></p>
                                                    </td>
                                                </tr>

                                                <tr className="all-delivery-charges">
                                                    <td>
                                                        <div className="row ">
                                                            <div className="column delivery_charge">
                                                                <h3 className="text-success fw-bold">Delivery Charge</h3>
                                                            </div>

                                                        </div>

                                                        <div className="d-flex d-none">
                                                            <div className="delivery_charge">
                                                                <h6 className="fs-15 fw-bold">
                                                                    shipping method </h6>
                                                            </div>
                                                            <div className="deliverycharge_currency ms-2 d-flex gap-1">
                                                                ₹ <span className="shipping_method"></span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex ">
                                                            <div className="delivery_charge">
                                                                <h6 className="fs-15 fw-bold">
                                                                    Delivery Charge with COD : </h6>
                                                            </div>
                                                            <div className="deliverycharge_currency ms-2 d-flex gap-1">
                                                                ₹ <span className="delivery_charge_with_cod">0.00</span>
                                                                <input type="hidden" name="delivery_charge_with_cod"
                                                                    className="delivery_charge_with_cod" value="0.00"/>

                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="delivery_charge">
                                                                <h6 className="fs-15 fw-bold">
                                                                    Delivery Charge without COD : </h6>
                                                            </div>
                                                            <div className="deliverycharge_currency ms-2 d-flex gap-1">
                                                                ₹ <span className="delivery_charge_without_cod">{cartTotalData.delivery_charge}</span>
                                                                <input type="hidden" name="delivery_charge_without_cod"
                                                                    className="delivery_charge_without_cod" value="0.00"/>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="delivery_charge">
                                                                <h6 className="fs-15 fw-bold">
                                                                    Estimated Delivery Date : </h6>
                                                            </div>
                                                            <div className="text-muted">
                                                                <h3 className="estimate_date"></h3>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>


                                                <tr>
                                                    <td className="text-muted">Wallet</td>
                                                    <td className="text-muted text-end d-flex gap-1">₹ <span
                                                            className="wallet_used">{isChecked ? userData.balance : 0}<span></span></span></td>

                                                </tr>
                                                <tr id="promocode_div" className="d-none">
                                                    <td className="text-muted">Promo code <span id="promocode"></span></td>
                                                    <td className="text-muted text-end"> <i>₹</i> <span id="promocode_amount"></span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                </tr>
                                                <tr className="total-price">
                                                    <td><strong className="text-dark">Total</strong></td>
                                                    <td className="d-flex gap-1 fw-bold">
                                                        ₹<span id="final_total">{finalBalance} </span>
                                                        <p></p>
                                                    </td>
                                                </tr>

                                            </tfoot>
                                        </table>
                                    </div>
                                    <div className="input-group">
                                        <a href="#" className="mb-4 pl-3 text-decoration-none text-blue fw-bold" data-bs-toggle="modal"
                                            data-bs-target="#promo-code-modal">
                                            See All Offers(%)
                                        </a>

                                    </div>
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Promo code" id="promocode_input"/>
                                        <div className="input-group-append">
                                            <button className="btn bg-secondary rounded-end text-white fw-bold" id="redeem_btn">Redeem</button>
                                            <button className="btn btn-danger d-none" id="clear_promo_btn">Clear</button>
                                        </div>
                                    </div>
                                    <button className="block btn bg-secondary rounded-pill text-white fw-bold w-100 mt-4" 
                                        onClick={placeOrder}
                                        type="submit">Place Order</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Billing Address */}
                <div 
                    className="offcanvas offcanvas-end" 
                    tabIndex="-1" 
                    id="offcanvasRightAddress" 
                    aria-labelledby="offcanvasRightLabel"
                    style={{ width }}
                >
                    <div className="offcanvas-header">
                        <h5 id="offcanvasRightLabel">Shipping Address</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    
                    <div className="offcanvas-body">
                        <div style={{ marginTop: '-2rem' }}>
                            <div className="row">
                                <section id="address_form">
                                    <ul id="address-list">
                                        {address &&
                                            address.map((item, index) => {
                                                return (
                                                    <label for="select-address-9"
                                                        className="form-check-label"
                                                        key={index}
                                                        onClick={() => setDisplayAddress(item)}
                                                    >
                                                        <li className="list-group-item d-flex justify-content-between lh-condensed mt-3">
                                                            <div className="col-md-1 h-100 my-auto">
                                                                <input 
                                                                    type="radio" 
                                                                    className="select-address form-check-input m-0"
                                                                    checked={displayAddress && displayAddress.name === item.name}
                                                                    name="select-address" 
                                                                    data-index={index} 
                                                                    id={`select-address-${index}`}
                                                                />
                                                            </div>
                                                            <div className="row text-start col-11">
                                                                <div className="text-dark"><i className="fa-solid fa-location-dot"></i> {item.name} - {item.type}</div>
                                                                <small className="col-12 text-muted">{item.address}</small>
                                                                <small className="col-12 text-muted">
                                                                    {item.state} , {item.country} - {item.pincode}
                                                                </small>
                                                                <small className="col-12 text-muted">{item.mobile}</small>
                                                            </div>
                                                        </li>
                                                    </label>
                                                );
                                        })}
                                    </ul>
                                    <div className="col-12 text-right mt-2 sticky-bottom">
                                        <a href="#" className="float-end " 
                                            data-bs-toggle="offcanvas"
                                            data-bs-target="#offcanvasRightNewAddress" 
                                            aria-controls="offcanvasRightNewAddress"
                                        >
                                            Create a New Address
                                        </a>
                                    </div>
                                    <footer className="mt-4 sticky-bottom">
                                        <button data-bs-dismiss="modal" className="btn btn-sm btn-soft-dark rounded-pill">Cancel</button>
                                        {/* <button className="submit btn btn-sm btn-primary rounded-pill" id="select_address">Save</button> */}
                                    </footer>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>

                {/* New Address */}
                <div 
                    className="offcanvas offcanvas-end" 
                    tabIndex="-1" 
                    id="offcanvasRightNewAddress" 
                    aria-labelledby="offcanvasRightLabel"
                    style={{ width }}
                >
                    <div className="offcanvas-header">
                        <h5 id="offcanvasRightLabel">Add Address</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>

                    <div className="offcanvas-body">
                        <div style={{ marginTop: '-2rem' }}>
                            <div className="row">
                                <section className="mt-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <label className="form-label">Name</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Name"
                                                className="form-control"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label className="form-label">Mobile</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Mobile"
                                                className="form-control"
                                                value={mobile}
                                                onChange={(e) => setMobile(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label className="form-label">Alternate Mobile</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Alternate Mobile"
                                                className="form-control"
                                                value={altMobile}
                                                onChange={(e) => setAltMobile(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-12 mt-2">
                                            <label className="form-label">Address</label>
                                            <textarea
                                                className="form-control"
                                                rows={4}
                                                placeholder="#Door no, Street Address, Locality, Area, Pincode"
                                                value={addressFiled}
                                                onChange={(e) => setAddressFiled(e.target.value)}
                                            ></textarea>
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label className="form-label">City</label>
                                            <Select
                                                className="select flex-grow-1"
                                                options={citiesDDL?.map((city) => ({
                                                    value: city.id,
                                                    label: city.name,
                                                }))}
                                                placeholder="Choose city"
                                                value={city}
                                                onChange={setCity}
                                            />
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label className="form-label">Area</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Area"
                                                className="form-control"
                                                value={area}
                                                onChange={(e) => setArea(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label className="form-label">Pincode</label>
                                            <Select
                                                className="select flex-grow-1 me-3"
                                                options={[
                                                    ...zipcodesDDL.map((zip) => ({
                                                        value: zip.zipcode,
                                                        label: zip.zipcode,
                                                    })),
                                                    { value: "other", label: "Other" },
                                                ]}
                                                placeholder="Choose pincode"
                                                value={pincode}
                                                onChange={handleSelectChange}
                                            />
                                            {isOther && (
                                                <input
                                                    type="text"
                                                    className="form-control flex-grow-1 mt-2"
                                                    placeholder="Enter custom pincode"
                                                    value={customPincode}
                                                    onChange={(e) => setCustomPincode(e.target.value)}
                                                />
                                            )}
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label className="form-label">State</label>
                                            {/* <input
                                                type="text"
                                                placeholder="Enter State"
                                                className="form-control"
                                                value={state}
                                                onChange={(e) => setState(e.target.value)}
                                            /> */}
                                            <select>
                                                <option>Telangana</option>
                                                <option>Andhra Pradesh</option>
                                                <option>Tamilnadu</option>
                                                <option>Karnataka</option>
                                                <option>Bihar</option>
                                                <option>Uttar Pradesh</option>
                                            </select>
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label className="form-label">Country</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Country"
                                                className="form-control"
                                                value={country}
                                                onChange={(e) => setCountry(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-4 mt-2">
                                            <label>Type</label>
                                            <div className="d-flex justify-content-between mt-3">
                                                <div style={{ cursor: 'pointer' }}>
                                                    <input
                                                        type="checkbox"
                                                        style={{ cursor: 'pointer' }}
                                                        id="Home"
                                                        checked={selectedOptionType === "Home"}
                                                        onChange={() => handleCheckboxChangeType("Home")}
                                                    />
                                                    <label className="ms-2" style={{ cursor: 'pointer' }} htmlFor="Home">Home</label>
                                                </div>
                                                <div style={{ cursor: 'pointer' }}>
                                                    <input
                                                        type="checkbox"
                                                        style={{ cursor: 'pointer' }}
                                                        id="Office"
                                                        checked={selectedOptionType === "Office"}
                                                        onChange={() => handleCheckboxChangeType("Office")}
                                                    />
                                                    <label className="ms-2" style={{ cursor: 'pointer' }} htmlFor="Office">Office</label>
                                                </div>
                                                <div style={{ cursor: 'pointer' }}>
                                                    <input
                                                        type="checkbox"
                                                        style={{ cursor: 'pointer' }}
                                                        id="Other"
                                                        checked={selectedOptionType === "Other"}
                                                        onChange={() => handleCheckboxChangeType("Other")}
                                                    />
                                                    <label className="ms-2" style={{ cursor: 'pointer' }} htmlFor="Other">Other</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <footer className="mt-4 sticky-bottom">
                                        <button data-bs-dismiss="modal" className="btn btn-sm btn-soft-dark border-secondary me-2 rounded-pill">
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="submit btn btn-sm bg-secondary text-white rounded-pill"
                                            onClick={handleSave}
                                        >
                                            Save
                                        </button>
                                    </footer>
                                </section>
                            </div>
                        </div>
                    </div>

                    {/* <div className="offcanvas-body">
                        <div style={{ marginTop: '-2rem' }}>
                            <div className="row">
                                <section className="mt-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <label className="form-label">Name</label>
                                            <input 
                                                type="text"
                                                placeholder="Enter Name"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label className="form-label">Mobile</label>
                                            <input 
                                                type="text"
                                                placeholder="Enter Mobile"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label className="form-label">Alternate Mobile</label>
                                            <input 
                                                type="text"
                                                placeholder="Enter Alternate Mobile"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-12 mt-2">
                                            <label className="form-label">Address</label>
                                            <textarea className="form-control" 
                                                rows={4}
                                                placeholder="#Door no, Street Address, Locality, Area, Pincode"
                                            ></textarea>
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label className="form-label">City</label>
                                            <Select
                                                className="select flex-grow-1"
                                                options={citiesDDL?.map((city) => ({
                                                    value: city.id,
                                                    label: city.name,
                                                }))}
                                                placeholder="Choose city"
                                            />
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label className="form-label">Area</label>
                                            <input 
                                                type="text"
                                                placeholder="Enter Area"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label className="form-label">Pincode</label>
                                            <Select
                                                className="select flex-grow-1"
                                                options={zipcodesDDL?.map((zip) => ({
                                                    value: zip.id,
                                                    label: zip.zipcode,
                                                }))}
                                                placeholder="Choose pincode"
                                            />
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label className="form-label">State</label>
                                            <input 
                                                type="text"
                                                placeholder="Enter State"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label className="form-label">Country</label>
                                            <input 
                                                type="text"
                                                placeholder="Enter Country"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-4 mt-2">
                                            <label>Type</label>
                                            <div className="d-flex justify-content-between mt-3">
                                                <div style={{ cursor: 'pointer' }}>
                                                    <input 
                                                        type="checkbox"
                                                        style={{ cursor: 'pointer' }}
                                                        id="Home"
                                                    />
                                                    <label className="ms-2" style={{ cursor: 'pointer' }} htmlFor="Home">Home</label>
                                                </div>
                                                <div style={{ cursor: 'pointer' }}>
                                                    <input 
                                                        type="checkbox"
                                                        style={{ cursor: 'pointer' }}
                                                        id="Office"
                                                    />
                                                    <label className="ms-2" style={{ cursor: 'pointer' }} htmlFor="Office">Office</label>
                                                </div>
                                                <div style={{ cursor: 'pointer' }}>
                                                    <input 
                                                        type="checkbox"
                                                        style={{ cursor: 'pointer' }}
                                                        id="Other"
                                                    />
                                                    <label className="ms-2" style={{ cursor: 'pointer' }} htmlFor="Other">Other</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <footer className="mt-4 sticky-bottom">
                                        <button data-bs-dismiss="modal" className="btn btn-sm btn-soft-dark border-secondary me-2 rounded-pill">Cancel</button>
                                        <button className="submit btn btn-sm bg-secondary text-white rounded-pill" id="select_address">Save</button>
                                    </footer>
                                </section>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </Base>
    )
}