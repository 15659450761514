import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoImg from "../../Assests/Images/logo-remove.png";
import FooterPaymentImg from "../../Assests/Images/footer-payments.png";
import PlaneImg from "../../Assests/Images/plane.png";
import AppleImg from "../../Assests/Images/apple.png";
import LockImg from "../../Assests/Images/lock.png";
import AndroidImg from "../../Assests/Images/android.png";
import MessageImg from "../../Assests/Images/message.png";
import { BASE_API_URL } from "./Config";
import axios from "axios";
import SignIn from "../Auth/Signin";
import { Popover } from "react-tiny-popover";
import Swal from "sweetalert2";
import RazorPay from './razorpay.png';


const Base = ({ children }) => {
  const [userData, setUserData] = useState([]);
  const [width, setWidth] = useState("55%");
  const [favItems, setFavItems] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [favQuantities, setfavQuantities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [cartQuantities, setCartQuantities] = useState([]);
  const [cartTotalAmount, setCartTotalAmount] = useState(0);
  const [displayNavigations, setDisplayNavigations] = useState(false);
  const [favErrorMessage, setFavErrorMessage] = useState("");
  const [cartErrorMessage, setCartErrorMessage] = useState("");
  const [search, setSearch] = useState("");
  const [productDetails, setProductDetails] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUserData = sessionStorage.getItem("UserData");
    if (storedUserData) {
      try {
        setUserData(JSON.parse(storedUserData));
      } catch (error) {
        console.error("Error parsing storedUserData:", error);
      }
    }
  }, []);

  useEffect(() => {
    if (userData.id > 0) {
      setDisplayNavigations(true);
    }
  }, [userData.id]);

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearch(value);

    if (value.trim()) {
      try {
        const formData = new FormData();
        formData.append("search", value);

        const response = await axios.post(
          `${BASE_API_URL}get_products`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        setProductDetails(response.data.data || []);
        setShowDropdown(true);
      } catch (err) {
        console.error("Error fetching product data:", err);
        setShowDropdown(false);
      }
    } else {
      setProductDetails([]);
      setShowDropdown(false);
    }
  };

  const handleSelectProduct = (product) => {
    console.log("Selected Product:", product);
    setSearch(product.name); // Update input value
    setShowDropdown(false); // Close dropdown
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(max-width: 768px)").matches) {
        setWidth("100%");
      } else {
        setWidth("50%");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", userData.id);

      const response = await axios.post(
        `${BASE_API_URL}get_categories`,
        formData,
        {}
      );
      setCategories(response.data.data);
    } catch (err) {
      console.log("failed fetching categories..!");
    }
  };

  useEffect(() => {
    fetchFavItems();
  }, [userData]);

  const fetchFavItems = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", userData.id);

      const response = await axios.post(
        `${BASE_API_URL}get_favorites`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userData.apikey}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setFavItems(response.data.data);
      // console.log(response.data.data);
    } catch (err) {
      console.log("failed fetching fav items..!");
    }
  };

  useEffect(() => {
    fetchCartItems();
    fetchTransactions();
  }, [userData]);

  const fetchCartItems = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", userData.id);

      const response = await axios.post(
        `${BASE_API_URL}get_user_cart`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userData.apikey}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data.data);
      setCartItems(response.data.data);
    } catch (err) {
      console.log("failed fetching fav items..!");
    }
  };

  const fetchTransactions = async () => {
      if (userData.id) {
        try {
          const response = await axios.get(
            `https://givvseller.ibizaccounts.in/GetTransactions?UserId=${userData.id}`
          );
          setTransactions(response.data.ResultData);
          // console.log(response.data, "transactions data");
        } catch (error) {
          console.error("Error fetching alert data:", error);
        }
      }
  };

  useEffect(() => {
    if (favItems && favItems.length > 0) {
      setfavQuantities(favItems.map(() => 1));
    }
  }, [favItems]);

  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      setCartQuantities(cartItems.map((item) => item.qty || 1));
    }
  }, [cartItems]);

  useEffect(() => {
    const total = (cartItems || []).reduce((sum, item, index) => {
      const price = Number(item.special_price || 0);
      const quantity = cartQuantities[index] || 1;
      return sum + price * quantity;
    }, 0);
    setCartTotalAmount(total);
  }, [cartItems, cartQuantities]);

  const handleCartIncrement = (index) => {
    setCartQuantities((prev) => {
      const updated = [...prev];
      updated[index] = (Number(updated[index]) || 1) + 1;
      // console.log("Incremented quantity:", updated[index]); // Log new value
      return updated;
    });
  };

  const handleCartDecrement = (index) => {
    setCartQuantities((prev) => {
      const updated = [...prev];
      updated[index] = Math.max(1, updated[index] - 1);
      // console.log("Decremented quantity:", updated[index]);
      return updated;
    });
  };

  const handleFavIncrement = (index) => {
    setfavQuantities((prev) => {
      const updated = [...prev];
      const maxAllowed = favItems[index].total_allowed_quantity || Infinity;

      if (updated[index] + 1 > maxAllowed) {
        setFavErrorMessage((errors) => ({
          ...errors,
          [index]: `Maximum quantity allowed for ${favItems[index].name} is ${maxAllowed}.`,
        }));
        return prev; // Do not increment
      }

      setFavErrorMessage((errors) => {
        const updatedErrors = { ...errors };
        delete updatedErrors[index]; // Clear error for this product
        return updatedErrors;
      });

      updated[index] += 1;
      return updated;
    });
  };

  const handleFavDecrement = (index) => {
    setfavQuantities((prev) => {
      const updated = [...prev];
      updated[index] = Math.max(1, updated[index] - 1);

      setFavErrorMessage((errors) => {
        const updatedErrors = { ...errors };
        delete updatedErrors[index]; // Clear error for this product
        return updatedErrors;
      });

      return updated;
    });
  };

  const totalAmount = (favItems || []).reduce((total, item, index) => {
    const price = Number(item.min_max_price?.special_price || 0);
    const quantity = favQuantities[index] || 1;
    return total + price * quantity;
  }, 0);

  const ClearFavItem = async (id) => {
    try {
      const formData = new FormData();
      formData.append("user_id", userData.id);
      formData.append("product_id", id);

      const response = await axios.post(
        `${BASE_API_URL}remove_from_favorites`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userData.apikey}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);

      fetchFavItems();
      fetchCartItems();
    } catch (err) {
      console.error("Failed fetching favorite items:", err);
    }
  };

  const ClearAllFavItems = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", userData.id);

      const response = await axios.post(
        `${BASE_API_URL}remove_from_favorites`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userData.apikey}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      fetchFavItems();
    } catch (err) {
      console.error("Failed fetching favorite items:", err);
    }
  };

  const ClearCartItem = async (id) => {
    console.log("celar step cart item fectching", id);
    try {
      const formData = new FormData();
      formData.append("user_id", userData.id);
      formData.append("product_variant_id", id);

      const response = await axios.post(
        `${BASE_API_URL}remove_from_cart`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userData.apikey}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      if (response.data.error) {
        setCartErrorMessage(response.data.message);
      } else {
        fetchCartItems();
        console.log("fetching cart items afetr remove");
      }
    } catch (err) {
      console.error("Failed fetching favorite items:", err);
    }
  };

  const ClearAllCartItems = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", userData.id);

      const response = await axios.post(
        `${BASE_API_URL}remove_from_cart`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userData.apikey}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      fetchCartItems();
    } catch (err) {
      console.error("Failed fetching favorite items:", err);
    }
  };

  const scrollableRef = useRef(null);

  // const scrollLeft = () => {
  //   if (scrollableRef.current) {
  //     scrollableRef.current.scrollBy({ left: -200, behavior: "smooth" });
  //   }
  // };

  // const scrollRight = () => {
  //   if (scrollableRef.current) {
  //     scrollableRef.current.scrollBy({ left: 200, behavior: "smooth" });
  //   }
  // };

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isPopoverOpenMobile, setIsPopoverOpenMobile] = useState(false);

  const togglePopover = () => {
    setIsPopoverOpen((prev) => !prev);
  };

  const togglePopoverMobile = () => {
    setIsPopoverOpenMobile((prev) => !prev);
  };

  // const handleLogout = (e) => {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   Swal.fire({
  //     title: "Are you sure you want to log out?",
  //     text: "You will need to log in again to access your account.",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, log out",
  //     cancelButtonText: "Cancel",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       sessionStorage.clear();
  //       localStorage.clear();
  //       setDisplayNavigations(false);
  //       Swal.fire(
  //         "Logged Out!",
  //         "You have been successfully logged out.",
  //         "success"
  //       );
  //     }
  //   });
  // };

  const handleLogout = (e) => {
    console.log('click')
    e.stopPropagation();
    // e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to log out?",
      text: "You will need to log in again to access your account.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, log out",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        localStorage.clear();
        setDisplayNavigations(false);
        Swal.fire(
          "Logged Out!",
          "You have been successfully logged out.",
          "success"
        );
      }
    });
  };

  const popoverRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setIsPopoverOpen(false);
        setIsPopoverOpenMobile(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAddToCart = async (qty, productVariantId) => {
    const url = `${BASE_API_URL}manage_cart`;

    if (!productVariantId || qty < 1)
      return alert("Invalid product or quantity");

    try {
      const formData = new FormData();
      formData.append("user_id", userData.id);
      formData.append("qty", qty);
      formData.append("product_variant_id", productVariantId);

      console.log("Sending request with API key:", userData.apikey);

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userData.apikey}`,
        },
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Cart response:", data);

        if (!data.error) {
          alert("Product added to cart successfully!");
          console.log("celar step1");
          ClearFavItem(productVariantId);
          // window.location.reload();
        } else {
          alert("Failed to add product to cart. Error: " + data.message);
        }
      } else {
        console.error("Error adding to cart:", response.statusText);
        alert("Failed to add product to cart.");
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      alert("An error occurred while adding to cart.");
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const handleCheckout = async () => {
    console.log("click checkout");
    const userId = userData.id;

    const cartData = cartItems.map((item, index) => ({
      product_variant_id: item.product_variant_id,
      qty: cartQuantities[index] || 1,
      user_id: userId,
    }));

    try {
      const promises = cartData.map(async (data) => {
        const formData = new FormData();
        formData.append("product_variant_id", data.product_variant_id);
        formData.append("qty", data.qty);
        formData.append("user_id", data.user_id);

        formData.forEach((value, key) => {
          console.log(`${key}: ${value}`);
        });

        const response = await axios.post(
          `${BASE_API_URL}manage_cart`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userData.apikey}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log(response.data);
        if (response.data.error) {
          alert(response.data.message);
        }
        return response.data;
      });

      const results = await Promise.all(promises);
      if (!results[0].error) {
        alert("Cart updated successfully! Redirecting to checkout...");
        // navigate("/billing-details");
        window.location.href = "/view-cart";
      }

      // console.log("Cart updated successfully:", results);
    } catch (error) {
      console.error("Error updating cart:", error);
      alert("Failed to update the cart. Please try again.");
    }
  };

  const fetchSettings = async () => {
    try {
      const response = await axios.post(
        `${BASE_API_URL}get_settings`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.data.error) {
        sessionStorage.setItem(
          "settingsData",
          JSON.stringify(response.data.data)
        );
      }
      // console.log('Settings fetched successfully:', response.data);
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollableRef.current.offsetLeft);
    setScrollLeft(scrollableRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollableRef.current.offsetLeft;
    const walk = (x - startX) * 1; // Adjust drag sensitivity
    scrollableRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUpOrLeave = () => {
    setIsDragging(false);
  };

  return (
    <div style={{ fontFamily: 'raleway' }}>
      <header className="container-fluid">
        <div className="header-top container py-4 d-lg-block d-none">
          <div className="row align-items-center">
            <div className="col-md-2">
              <Link to="/" className="logo">
                <img src={LogoImg} className="img-fluid" alt="Logo" />
              </Link>
            </div>
            <div className="col-md-7 d-none d-lg-block">
              <div className="row">
                <div className="col-lg-9 col-md-7">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control rounded-0"
                      placeholder="Search for gifts"
                      value={search}
                      onChange={handleSearchChange}
                    />
                    <button className="bg-secondary text-white border-0 p-2 px-3">
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                  {showDropdown && productDetails.length > 0 && (
                    <ul
                      className="dropdown-menu show w-40 position-absolute"
                      style={{
                        zIndex: 1000,
                        maxHeight: "200px",
                        overflowY: "auto",
                      }}
                    >
                      {productDetails.map((product, index) => (
                        <li
                          key={index}
                          className="dropdown-item"
                          onClick={() => handleSelectProduct(product)}
                          style={{ cursor: "pointer" }}
                        >
                          <Link
                            to={`/product-details/${product.id}`}
                            className="text-dark text-decoration-none"
                          >
                            <img
                              src={product.image}
                              style={{ width: "2rem" }}
                              className="me-2 rounded"
                            />
                            {product.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="col-lg-3 col-md-5">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control rounded-0"
                      placeholder="Hyderabad"
                    />
                    <button className="bg-secondary text-white border-0 p-2 px-3">
                      <i className="fa-solid fa-location-dot"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 d-flex justify-content-end align-items-center">
              <div className="d-none d-md-flex gap-4">
                <a
                  className="text-secondary"
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRightTrans"
                  aria-controls="offcanvasRightTrans"
                >
                  <i className="fa-solid fa-indian-rupee-sign fs-4"></i>
                </a>
                <a
                  className={`text-secondary user-icon ${
                    displayNavigations ? "d-none" : "d-block"
                  }`}
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="modal"
                  data-bs-target="#modal-signin"
                >
                  <i className="fa-regular fa-user fs-4"></i>
                </a>
                {displayNavigations && (
                  <Popover
                    isOpen={isPopoverOpen}
                    positions={["top", "bottom", "left", "right"]}
                    content={
                      <div
                        className="mt-4 card shadow"
                        ref={popoverRef}
                        style={{ width: "10rem" }}
                      >
                        <div className="card-bod">
                          <h6 className="text-center mt-2 text-secondary">
                            <i className="fa-regular fa-circle-user me-2 fa-lg"></i>
                            {userData?.username}
                          </h6>
                          <hr />
                          <ul
                            style={{ listStyleType: "none" }}
                            className="mt-2"
                          >
                            <li>
                              <Link
                                to="/my-account?content=myOrdersContent"
                                className="text-dark"
                                style={{ textDecoration: "none" }}
                              >
                                <i className="fa-solid fa-boxes-stacked me-3"></i>
                                Orders
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/my-account"
                                className="text-dark"
                                style={{ textDecoration: "none" }}
                              >
                                <i className="fa-regular fa-user me-3 my-3"></i>
                                Profile
                              </Link>
                            </li>
                            <li
                              onClick={handleLogout}
                              className="text-dark"
                              style={{ textDecoration: "none", cursor: "pointer" }}
                            >
                              <i className="fa-solid fa-arrow-right-from-bracket me-3"></i>
                              Logout
                            </li>
                          </ul>
                        </div>
                      </div>
                    }
                  >
                    <div onClick={togglePopover}>
                      <i
                        className="fa-regular fa-user text-secondary fs-4"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </div>
                  </Popover>
                )}
                <div
                  className="position-relative"
                  style={{ display: "inline-block" }}
                >
                  <a
                    className="text-secondary"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fa-regular fa-heart fs-4"></i>
                  </a>
                  <span
                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary"
                    style={{
                      fontSize: "0.75rem",
                      padding: "2px 6px",
                    }}
                  >
                    {(favItems && favItems.length) || 0}
                  </span>
                </div>
                <div
                  className="position-relative"
                  style={{ display: "inline-block" }}
                >
                  <a
                    className="text-secondary"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRightCart"
                    aria-controls="offcanvasRightCart"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fa-solid fa-cart-shopping fs-4"></i>
                  </a>
                  <span
                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary"
                    style={{
                      fontSize: "0.75rem",
                      padding: "2px 6px",
                    }}
                  >
                    {(cartItems && cartItems.length) || 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile navigation */}
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <a className="navbar-brand d-lg-none d-block" href="/">
              <img src={LogoImg} className="img-fluid" alt="Logo" />
            </a>
            <div className="d-lg-none d-block d-flex ms-auto">
              <a className="text-secondary mx-1">
                <i className="fas fa-search fs-4"></i>
              </a>
              <a
                className="text-secondary ms-1"
                style={{ cursor: "pointer" }}
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRightTrans"
                aria-controls="offcanvasRightTrans"
              >
                <i className="fa-solid fa-indian-rupee-sign fs-4 me-2"></i>
              </a>
              <a
                className={`text-secondary user-icon mx-1 ${
                  displayNavigations ? "d-none" : "d-block"
                }`}
                style={{ cursor: "pointer" }}
                data-bs-toggle="modal"
                data-bs-target="#modal-signin"
              >
                <i className="fa-regular fa-user fs-4"></i>
              </a>
              {displayNavigations && (
                <Popover
                  containerStyle={{ zIndex: 20000 }}
                  isOpen={isPopoverOpenMobile}
                  positions={["top", "bottom", "left", "right"]}
                  content={
                    <div
                      className="mt-4 card shadow"
                      ref={popoverRef}
                      style={{ width: "10rem" }}
                    >
                      <div className="card-bod">
                        <h6 className="text-center mt-2 text-secondary">
                          <i className="fa-regular fa-circle-user me-2 fa-lg"></i>
                          {userData?.username}
                        </h6>
                        <hr />
                        <ul style={{ listStyleType: "none" }} className="mt-2">
                          <li>
                            <Link
                              to="/my-account?content=myOrdersContent"
                              className="text-dark"
                              style={{ textDecoration: "none" }}
                              onClick={() => setIsPopoverOpenMobile(false)}
                            >
                              <i className="fa-solid fa-boxes-stacked me-3"></i>
                              Orders
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/my-account"
                              className="text-dark"
                              style={{ textDecoration: "none" }}
                            >
                              <i className="fa-regular fa-user me-3 my-3"></i>
                              Profile
                            </Link>
                          </li>
                          <li
                            onClick={(e) => {
                              handleLogout(e);
                              setIsPopoverOpenMobile(false);
                            }}
                            className="text-dark"
                            style={{ textDecoration: "none" }}
                          >
                            <i className="fa-solid fa-arrow-right-from-bracket me-3"></i>
                            Logout
                          </li>
                        </ul>
                      </div>
                    </div>
                  }
                >
                  <div onClick={togglePopoverMobile}>
                    <i
                      className="fa-regular fa-user text-secondary fs-4"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </div>
                </Popover>
              )}
              <div
                  className="position-relative"
                  style={{ display: "inline-block" }}
                >
              <a
                className="text-secondary ms-1 me-2"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <i className="fa-regular fa-heart fs-4"></i>
              </a>
              <span
                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary"
                    style={{
                      fontSize: "0.65rem",
                      padding: "2px 5px",
                      marginRight: "10px"
                    }}
                  >
                    {(favItems && favItems.length) || 0}
                  </span>
              </div>
              <a
                className="text-secondary mx-1"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRightCart"
                aria-controls="offcanvasRightCart"
              >
                <i className="fa-solid fa-cart-shopping fs-4"></i>
              </a>
              
            </div>
            <div
              className={`d-flex align-items-center d-none d-md-block d-lg-block`}
              style={{ overflow: "hidden" }}
            >
              <div
                className="collapse navbar-collapse"
                id="navbarNavDropdown"
                style={{
                  flex: 1,
                  overflowX: "hidden",
                  display: "flex",
                }}
              >
                <ul
                  ref={scrollableRef}
                  className="navbar-nav"
                  style={{
                    display: "flex",
                    whiteSpace: "nowrap",
                    overflowX: "auto",
                    padding: "0",
                    margin: "0",
                    listStyle: "none",
                    cursor: isDragging ? "grabbing" : "grab",
                    scrollbarWidth: "none",
                  }}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUpOrLeave}
                  onMouseLeave={handleMouseUpOrLeave}
                >
                  <li
                    className="nav-item text-uppercase"
                    style={{
                      flexShrink: 0,
                      padding: "0 10px",
                    }}
                  >
                    <Link className="nav-link" to={`/products/0`}>
                      ALL
                    </Link>
                  </li>
                  {categories &&
                    categories.map((item, index) => (
                      <li
                        className="nav-item text-uppercase"
                        key={index}
                        style={{
                          flexShrink: 0,
                          padding: "0 10px",
                        }}
                      >
                        <Link className="nav-link" to={`/products/${item.id}`}>
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  {/* <li
                    className="nav-item text-uppercase"
                    style={{
                      flexShrink: 0,
                      padding: "0 10px",
                    }}
                  >
                    <Link className="nav-link" to={`/products/0`}>
                      ALL
                    </Link>
                  </li> */}
                </ul>
              </div>
              <style>
                {`
                  .navbar-nav::-webkit-scrollbar {
                      display: none; /* Hide scrollbar for webkit browsers */
                  }
                `}
              </style>
            </div>
          </div>
        </nav>
      </header>

      <div className="hr container border-bottom-2 mb-4"></div>

      <main className=" font-rock">
        {children}
        <div className="home-join py-5 bg-primary">
          <div className="join-inner container font-rock">
            <h2 className="text-center text-white fs-1  mb-3">
              JOIN THE GIIVV CLUB
            </h2>
            <h4 className="text-center">
              Start earning rewards and stay up to date on new arrivals,
              top-sellers and deals at Giivv!
            </h4>
            <div className="row justify-content-center w-100 my-4">
              <div className="col-lg-4">
                <div className="join-input position-relative">
                  <input
                    type="text"
                    placeholder="Email address"
                    className="w-100 border-0 fs-5 p-3 "
                  />
                  <div className="join-icon position-absolute">
                    <i className="fas fa-envelope fs-2 text-secondary"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-services py-5 bg-light">
          <div className="container">
            <div className="home-service-inner row font-rock">
              <div className="home-service-one col-lg-3 col-md-4 col-6">
                <div className="text-center">
                  {" "}
                  <img src={PlaneImg} className="w-20 my-4" alt="" />
                </div>
                <h5 className="text-center fw-bold">Worldwide Delivery</h5>
              </div>
              <div className="home-service-one col-lg-3 col-md-4 col-6">
                <div className="text-center">
                  {" "}
                  <img src={LockImg} className="w-20 my-3" alt="" />
                </div>
                <h5 className="text-center fw-bold">
                  100% Safe & Secure Payments
                </h5>
              </div>
              <div className="home-service-one col-lg-3 col-md-4 col-6">
                <div className="text-center">
                  {" "}
                  <img src={MessageImg} className="w-20 my-4" alt="" />
                </div>
                <h5 className="text-center fw-bold">Dedicated Help Center</h5>
              </div>
              <div className="home-service-one col-lg-3 col-md-4 col-6">
                <div className="text-center">
                  <img src={AndroidImg} className="w-20 my-4" alt="" />
                  <img src={AppleImg} className="w-20 my-4" alt="" />
                </div>
                <h5 className="text-center fw-bold">Shop on the go</h5>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer>
        <div className="footer container-fluid py-3">
          <div className="container footer-inner py-3">
            <div className="row font-rock">
              <div className="col-lg-2 col-md-4 d-flex justify-content-md-start text-md-start text-center justify-content-center my-3">
                <div className="foot-col">
                  <h5 className="fw-bold mb-3 text-uppercase fs-6">SERVICES</h5>
                  <ul className="list-unstyled">
                    <li className="mb-2">
                      <Link to="/my-account?content=myDetailsContent" className="text-dark text-decoration-none">
                        My Account
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link to="/my-account?content=myDetailsContent" className="text-dark text-decoration-none">
                        Track Order
                      </Link>
                    </li>
                    <li className="mb-2">
                      <a href="#" className="text-dark text-decoration-none">
                        Delivery
                      </a>
                    </li>
                    <li className="mb-2"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                      style={{ cursor: 'pointer' }}
                    >
                      <a className="text-dark text-decoration-none">
                        Wishlist
                      </a>
                    </li>
                    {/* <li className="mb-2">
                      <a href="#" className="text-dark text-decoration-none">
                        Gift Wrapping & Cards
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 d-flex justify-content-md-start text-md-start text-center justify-content-center my-3">
                <div className="foot-col">
                  <h5 className="fw-bold mb-3 text-uppercase fs-6">About Us</h5>
                  <ul className="list-unstyled">
                    <li className="mb-2">
                      <a className="text-dark text-decoration-none">
                        Meet The Team
                      </a>
                    </li>
                    <li className="mb-2">
                      <Link
                        to="/contact-us"
                        className="text-dark text-decoration-none"
                      >
                        Contact Us
                      </Link>
                    </li>
                    {/* <li className="mb-2">
                      <a href="#" className="text-dark text-decoration-none">
                        Help Centre
                      </a>
                    </li> */}
                    {/* <li className="mb-2">
                      <a href="#" className="text-dark text-decoration-none">
                        Blog
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 d-flex justify-content-md-start text-md-start text-center justify-content-center my-3">
                <div className="foot-col">
                  <h5 className="fw-bold mb-3 text-uppercase fs-6">
                    Information
                  </h5>
                  <ul className="list-unstyled">
                    <li className="mb-2">
                      <Link
                        to="/privacy-policy"
                        className="text-dark text-decoration-none"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link
                        to="/return-policy"
                        className="text-dark text-decoration-none"
                      >
                        Returns Policy
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link
                        to="/terms-and-conditions"
                        className="text-dark text-decoration-none"
                      >
                        Terms & Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-10">
                <div className="foot-col">
                  <div className="row">
                    <div className="col-md-8 d-flex justify-content-md-start justify-content-center  my-3">
                      <div className="text-md-start text-center">
                        <h5 className="fw-bold mb-3 text-uppercase fs-6">
                          Secure Payments
                        </h5>
                        {/* <img
                          src={FooterPaymentImg}
                          className="img-fluid"
                          alt=""
                        /> */}
                        <img
                          src={RazorPay}
                          className="img-fluid"
                          style={{ width: '8rem' }}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-md-4 d-flex justify-content-md-start text-md-start text-center justify-content-center my-3">
                      <div className="foot-social">
                        <h5 className="fw-bold mb-3 text-uppercase fs-6">
                          Social Media
                        </h5>
                        <div className="foot-icons d-flex justify-content-between">
                          <a
                            href="https://www.facebook.com/people/Giivvcom/61566349427625/"
                            target="_blank"
                            className="text-secondary fs-4 mx-2"
                          >
                            <i className="fa-brands fa-facebook-f"></i>
                          </a>
                          <a
                            href="https://x.com/giivv_com"
                            className="text-secondary fs-4 mx-2"
                            target="_blank"
                          >
                            <i className="fa-brands fa-x-twitter"></i>
                          </a>
                          <a
                            href="https://www.instagram.com/giivv_com/?hl=en"
                            target="_blank"
                            className="text-secondary fs-4 mx-2"
                          >
                            <i className="fa-brands fa-instagram"></i>
                          </a>
                          <a
                            // target="_blank"
                            className="text-secondary fs-4 mx-2"
                          >
                            <i className="fa-brands fa-pinterest"></i>
                          </a>
                          <a
                            href="https://www.youtube.com/@Giivv.comofficial"
                            target="_blank"
                            className="text-secondary fs-4 mx-2"
                          >
                            <i className="fa-brands fa-youtube"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom bg-primary py-2 font-rock">
          <p className="text-white text-center mb-0 fs-5">
            Copyright 2024 giivv | All rights reserved |
          </p>
        </div>
      </footer>

      {/* Favorite Items */}
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        style={{ width }}
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">MY WISHLIST</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div className="offcanvas-body">
          <div id="myWishlistContent" className="content-section">
            <div className="wish-head pb-3 border-bottom-2">
              <div className="row">
                <div className="col-lg-6 col-5">
                  <p className="mb-0">Product</p>
                </div>
                <div className="col-lg-2 col-2">
                  <p className="mb-0">Price</p>
                </div>
                <div className="col-lg-2 col-3">
                  <p className="mb-0">Quantity</p>
                </div>
                <div className="col-lg-2 col-2 d-flex justify-content-end">
                  <p className="mb-0">Total</p>
                </div>
              </div>
            </div>
            {favItems &&
              favItems.map((item, index) => {
                const price = Number(item.variants[0]?.price || 0);
                const quantity = favQuantities[index] || 1;

                return (
                  <div
                    className="wish-content py-3 border-bottom-2"
                    key={index}
                  >
                    <div className="row">
                      <div className="col-lg-6 col-5">
                        <div className="wish-details row align-items-center my-2">
                          <div className="col-12 col-lg-4 my-2">
                            {/* <img
                                      src={item.image}
                                      className="img-fluid w-100"
                                      alt="Purple Majesty"
                                    /> */}
                            {item.image ? (
                              <img
                                src={item.image}
                                className="img-fluid w-100 rounded"
                                alt={item.name || "Product Image"}
                              />
                            ) : (
                              <div
                                className="img-fluid w-100 d-flex align-items-center justify-content-center"
                                style={{
                                  backgroundColor: "#f0f0f0",
                                  height: "100px",
                                  fontSize: "24px",
                                  color: "#333",
                                }}
                              >
                                {item.name?.charAt(0).toUpperCase() || "N"}
                              </div>
                            )}
                          </div>
                          <div className="col-12 col-lg-8 ps-lg-4 my-2">
                            <h6 className="fw-bold">{item.name}</h6>
                            <p className="fst-italic mb-0">
                              by {item.seller_name}
                            </p>
                            <div className="wish-ratings text-secondary">
                              <span>
                                {[...Array(5)].map((_, idx) => (
                                  <span
                                    key={idx}
                                    style={{
                                      color:
                                        idx < Math.round(Number(item.rating))
                                          ? "gold"
                                          : "lightgray",
                                    }}
                                  >
                                    ★
                                  </span>
                                ))}
                              </span>
                              <span className="text-muted">
                                ({item.no_of_ratings})
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2 d-flex align-items-center">
                        <p className="mb-0">₹{price}</p>
                      </div>
                      <div className="col-lg-2 col-3 d-flex align-items-center">
                        <div>
                          <div className="d-flex align-items-center px-md-3 border-secondary">
                            <button
                              className="btn btn-outline-secondary decrement-btn border-0 p-1 py-1 fs-4"
                              onClick={() => handleFavDecrement(index)}
                            >
                              -
                            </button>
                            <input
                              type="text"
                              className="form-control text-center mx-1 quantity-input border-0"
                              style={{ width: "40px" }}
                              value={quantity}
                              readOnly
                            />
                            <button
                              className="btn btn-outline-secondary increment-btn border-0 p-1 py-1 fs-4"
                              onClick={() => handleFavIncrement(index)}
                            >
                              +
                            </button>
                          </div>
                          {favErrorMessage && (
                            <p
                              className="text-danger mt-2"
                              style={{ fontSize: "0.9rem" }}
                            >
                              {favErrorMessage[index]}
                            </p>
                          )}
                          <p className="fs-8 mb-0 mt-3">
                            Remove Item
                            <span
                              className="remove-wish ms-1 rounded-circle"
                              onClick={() => ClearFavItem(item.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <i className="fa-regular fa-trash-can text-danger"></i>
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-2 d-flex align-items-center justify-content-end">
                        <p className="mb-0">₹{(price * quantity).toFixed(2)}</p>
                      </div>
                    </div>
                    <div className="text-end">
                      <button
                        className="fs-8 border-0 text-white bg-secondary px-3 py-1 rounded-pill fw-bold"
                        onClick={() =>
                          handleAddToCart(
                            favQuantities[index],
                            item.variants[0].id
                          )
                        }
                      >
                        Add To Cart
                      </button>
                    </div>
                  </div>
                );
              })}
            <div className="wish-last py-3">
              <div className="d-flex justify-content-between">
                <p className="fs-8 mb-0">
                  Empty Wishlist{" "}
                  <span
                    className="remove-wis ms-1 rounded-circl"
                    onClick={() => ClearAllFavItems()}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fa-regular fa-trash-can text-danger"></i>
                  </span>
                </p>
                <p className="mb-0 fw-bold">Total ₹{totalAmount.toFixed(2)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Cart Items */}
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRightCart"
        aria-labelledby="offcanvasRightLabel"
        style={{ width }}
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">MY CART</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div className="offcanvas-body">
          <div id="myWishlistContent" className="content-section">
            <div className="wish-head pb-3 border-bottom-2">
              <div className="row">
                <div className="col-lg-6 col-5">
                  <p className="mb-0">Product</p>
                </div>
                <div className="col-lg-2 col-2">
                  <p className="mb-0">Price</p>
                </div>
                <div className="col-lg-2 col-3">
                  <p className="mb-0">Quantity</p>
                </div>
                <div className="col-lg-2 col-2 d-flex justify-content-end">
                  <p className="mb-0">Total</p>
                </div>
              </div>
            </div>
            {cartItems &&
              cartItems.map((item, index) => {
                const price = item.special_price || 0;
                const quantity = cartQuantities[index] || 1;
                return (
                  <div
                    className="wish-content py-3 border-bottom-2"
                    key={index}
                  >
                    <div className="row">
                      <div className="col-lg-6 col-5">
                        <div className="wish-details row align-items-center my-2">
                          <div className="col-12 col-lg-4 my-2">
                            <img
                              src={item.image}
                              className="img-fluid w-100"
                              alt={`${item.name}`}
                            />
                          </div>
                          <div className="col-12 col-lg-8 ps-lg-4 my-2">
                            <h6 className="fw-bold">{item.name}</h6>
                            {/* <p className="fst-italic mb-0">
                              by {item.seller_name}
                            </p> */}
                            <div className="wish-ratings text-secondary">
                              <span>
                                {[...Array(5)].map((_, idx) => (
                                  <span
                                    key={idx}
                                    style={{
                                      color:
                                        idx < Math.round(Number(item.rating))
                                          ? "gold"
                                          : "lightgray",
                                    }}
                                  >
                                    ★
                                  </span>
                                ))}
                              </span>
                              <span className="text-muted">
                                ({item.no_of_ratings || 0})
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2 d-flex align-items-center">
                        <p className="mb-0">₹{price}</p>
                      </div>
                      <div className="col-lg-2 col-3 d-flex align-items-center">
                        <div>
                          <div className="d-flex align-items-center px-md-3 border-secondary">
                            <button
                              className="btn btn-outline-secondary decrement-btn border-0 p-1 py-1 fs-4"
                              onClick={() => handleCartDecrement(index)}
                            >
                              -
                            </button>
                            <input
                              type="text"
                              className="form-control text-center mx-1 quantity-input border-0"
                              style={{ width: "40px" }}
                              value={quantity}
                              readOnly
                            />
                            <button
                              className="btn btn-outline-secondary increment-btn border-0 p-1 py-1 fs-4"
                              onClick={() => handleCartIncrement(index)}
                            >
                              +
                            </button>
                          </div>
                          <p className="fs-8 mb-0 mt-3">
                            Remove Item
                            <span
                              className="remove-wis ms-1 rounded-circe"
                              onClick={() => ClearCartItem(item.product_variant_id)}
                              style={{ cursor: "pointer" }}
                            >
                              <i className="fa-regular fa-trash-can text-danger"></i>
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-2 d-flex align-items-center justify-content-end">
                        <p className="mb-0">₹{price * quantity}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            <div className="text-end mt-2">
              <button
                type="button"
                className="fs-8 border-0 text-white bg-secondary px-3 py-1 rounded-pill fw-bold"
                onClick={handleCheckout}
              >
                Check Out
              </button>
            </div>
            <div className="wish-last py-3">
              <div className="d-flex justify-content-between">
                <p className="fs-8 mb-0">
                  Empty Cart
                  <span
                    className="remove-wis ms-1 rounded-circl"
                    onClick={() => ClearAllCartItems()}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fa-regular fa-trash-can text-danger"></i>
                  </span>
                </p>
                <p className="mb-0 fw-bold">
                  Total: ₹{cartTotalAmount.toFixed(2)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Transactions */}
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRightTrans"
        aria-labelledby="offcanvasRightLabel"
        style={{ width }}
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">MY TRANSACTIONS</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div className="offcanvas-body">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Order Id</th>
                  <th>Date</th>
                  <th>Amount(Rs.)</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {transactions && transactions.length > 0 &&
                  transactions.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="fw-bold">OR-{item.order_id}</td>
                      <td>{formatDate(item.transaction_date)}</td>
                      <td>{item.amount}</td>
                      <td>{item.status}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <SignIn />
    </div>
  );
};

export default Base;
