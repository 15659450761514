import React, { useState } from 'react';
import { BASE_API_URL } from '../Config/Config';
// import SignUp from './Signup';
import Swal from 'sweetalert2';
import OTPVerification from './Signup';

export default function SignIn () {

    const [loading, setLoading] = useState("");
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [errorMess, setErrorMess] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('mobile', `+91${mobile}`);
            formData.append('password', password);
            
            formData.forEach((value, key) => {
                console.log(`${key}: ${value}`);
            });

            const response = await fetch(`${BASE_API_URL}login`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Login failed');
            }

            const data = await response.json();
            console.log(data)

            if(data.error) {
                setErrorMess(data.message);
            }

            else if (data && data.data && data.data.length > 0) {
                sessionStorage.clear();
                localStorage.clear();
                sessionStorage.setItem("UserData", JSON.stringify(data.data[0]));
                Swal.fire({
                    title: 'Login Successful!',
                    text: 'Welcome back to your account.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(() => {
                    window.location.reload();
                });
            } else {
                Swal.fire({
                    title: 'Login Failed',
                    text: 'No user data found. Please check your credentials.',
                    icon: 'error',
                    confirmButtonText: 'Retry'
                });
            }
            
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="modal fade show" id="modal-signin" tabindex="-1" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content text-center">
                        <div className="modal-body">
                            <section id="login_div">
                                <div className='d-flex'>
                                    <h2 className="mb-3 text-start">Welcome Back</h2>
                                    <button type="button" className="btn-close d-flex ms-auto" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className=" mb-6 text-start">Fill your email and password to sign in.</p>
                                <form onSubmit={handleLogin}>
                                    <p className='form-label text-start' for="loginEmail">Enter Mobile / Email</p>
                                    <div className="input-group ">
                                        <span className="input-group-text">
                                            <img
                                                src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png"
                                                alt="India Flag"
                                                style={{ width: "20px", marginRight: "8px" }}
                                            />
                                            +91
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="identity"
                                            placeholder="Enter Mobile Number"
                                            id="mobile"
                                            value={mobile} 
                                            onChange={(e) => setMobile(e.target.value)}
                                            // onWheel={(e) => e.target.blur(e)}
                                        />
                                    </div>
                                    <div className="mb-4 text-start position-relative mt-1">
                                        <label className="form-label" htmlFor="loginPassword">Password</label>
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="form-control"
                                            name="password"
                                            placeholder="Password"
                                            id="loginPassword"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            style={{
                                                paddingRight: "2.5rem",
                                                height: "2.5rem",
                                            }}
                                        />
                                        <span
                                            className="position-absolute"
                                            onClick={togglePasswordVisibility}
                                            style={{
                                                cursor: "pointer",
                                                right: "0.75rem",
                                                top: "75%",
                                                transform: "translateY(-50%)",
                                                position: "absolute",
                                                fontSize: "1rem",
                                            }}
                                        >
                                            {showPassword ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>}
                                        </span>
                                    </div>

                                    {errorMess && <p className='text-danger'>{errorMess}</p>}
                                    
                                    <footer>
                                        <button type="button" data-bs-dismiss="modal" aria-label="Close"
                                            className="btn btn-soft-dark btn-sm rounded-pill">Cancel</button>
                                            <button type="submit" className="btn bg-secondary text-white btn-sm rounded-pill" disabled={loading}>
                                                {loading ? 'Logging in...' : 'Login'}
                                            </button>
                                    </footer>
                                    <br/>

                                    <p className="mb-1">
                                        <a className="text-decoration-none text-blue fs-15 hover">Forgot Password ?</a>
                                    </p>
                                    <p className="mb-0">Don't have an account?
                                        <a 
                                            className="text-decoration-none text-blue fs-15 hover" 
                                            style={{ cursor: 'pointer' }} 
                                            data-bs-toggle="modal" 
                                            data-bs-target="#modal-signup"
                                        >Sign up Here
                                        </a>
                                    </p>
                                </form>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <OTPVerification />
        </>
        
    );

}