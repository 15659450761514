import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { BASE_API_URL } from "../Config/Config";
// import SignIn from "./Signin";
import Swal from "sweetalert2";

// Your Firebase config
const firebaseConfig = {
    // apiKey: "AIzaSyCwONXvUYAs5sq2m5AwXqzqFzOQ8jm-vMI",
    // authDomain: "testforsuntek.firebaseapp.com",
    // projectId: "testforsuntek",
    // storageBucket: "testforsuntek.firebasestorage.app",
    // messagingSenderId: "976866454666",
    // appId: "1:976866454666:web:102df949d5ecd365cb1173",
    // measurementId: "G-0MRJ9VZM9P"
    apiKey: "AIzaSyCzAUyZaEyH-a3Wg3nHrpPtLGhhD6uybNk",
    authDomain: "giivv-357bd.firebaseapp.com",
    projectId: "giivv-357bd",
    storageBucket: "giivv-357bd.firebasestorage.app",
    messagingSenderId: "154591458957",
    appId: "1:154591458957:web:2ac09a845582022d9bd4c4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default function OTPVerification () {

    const [otpCode, setOtpCode] = useState("");
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    const [displayRegFields, setDisplayRegFields] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOTP] = useState('');
    const [referalCode, setReferalCode] = useState('');

    let recaptchaVerifier;

    useEffect(() => {
        if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
            auth,
            "recaptcha-container",
            {
            size: "invisible",
            callback: (response) => {
                // onSignup();
                console.log(response);
                setIsVerifying(false);
            },
            "expired-callback": () => {
                setError("reCAPTCHA expired. Please try again.");
                setIsVerifying(false);
            },
            }
        );
        }

        if (auth && process.env.NODE_ENV === "development") {
        auth.appVerificationDisabledForTesting = true; 
        }
    }, []);

    // const sendOTP = async () => {
    //     if (!mobile) {
    //       setError("Please enter a phone number.");
    //       return;
    //     }
      
    //     if (!/^\d{10}$/.test(mobile)) {
    //       setError("Please enter a valid 10-digit phone number.");
    //       return;
    //     }
      
    //     setLoading(true);
    //     setIsVerifying(true);
      
    //     const formattedMobile = `+91${mobile}`;
    //     console.log(`Verifying user with mobile: ${mobile}`);
      
    //     try {

    //         console.log("Starting ReCAPTCHA verification...");
    //         const captchaResponse = await window.recaptchaVerifier.verify();
    //         console.log("ReCAPTCHA verified successfully:", captchaResponse);
    
    //         // Step 1: Verify the user
    //         const formData = new FormData();
    //         formData.append("mobile", mobile);
        
    //         const response = await fetch(`${BASE_API_URL}verify_user`, {
    //             method: "POST",
    //             body: formData,
    //         });
        
    //         const result = await response.json();
    //         console.log(result, 'result verfiy_user');
        
    //         if (result.error) {
    //             setError(result.message || "User already exists.");
    //             setIsVerifying(false);
    //             return;
    //         }
        
    //         // const formattedMobile = "+919866954660"; 
    //         console.log(`Sending OTP to ${formattedMobile}`);

    //         const otpResult = await signInWithPhoneNumber(auth, formattedMobile, window.recaptchaVerifier);
        
    //             setDisplayRegFields(true);
    //             setConfirmationResult(otpResult);
    //             setError("");
    //             alert(`OTP sent to ${mobile}`);
    //         } catch (error) {
    //             console.error("Error:", error);
    //             setError(`Failed to send OTP: ${error.message}`);
    //             setDisplayRegFields(false);
    //         } finally {
    //             setLoading(false);
    //             setIsVerifying(false);
    //             setDisplayRegFields(false);
    //         }
    // };

    const sendOTP = async () => {
        if (!mobile) {
            setError("Please enter a phone number.");
            return;
        }
    
        if (!/^\d{10}$/.test(mobile)) {
            setError("Please enter a valid 10-digit phone number.");
            return;
        }
    
        setLoading(true);
        setIsVerifying(true);
    
        const formattedMobile = `+91${mobile}`;
        console.log(`Verifying user with mobile: ${formattedMobile}`);
    
        try {
            console.log("Starting ReCAPTCHA verification...");
            const captchaResponse = await window.recaptchaVerifier.verify();
            console.log("ReCAPTCHA verified successfully:", captchaResponse);
    
            const formData = new FormData();
            formData.append("mobile", formattedMobile);
    
            const response = await fetch(`${BASE_API_URL}verify_user`, {
                method: "POST",
                body: formData,
            });
    
            const result = await response.json();
            console.log(result, 'result verify_user');
    
            if (result.error) {
                setError(result.message || "User already exists.");
                setIsVerifying(false);
                return;
            }
    
            console.log(`Sending OTP to ${formattedMobile}`);
    
            const otpResult = await signInWithPhoneNumber(auth, formattedMobile, window.recaptchaVerifier);
    
            setConfirmationResult(otpResult);
            setError("");
            setDisplayRegFields(true);
            alert(`OTP sent to ${mobile}`);
    
        } catch (error) {
            console.error("Error:", error);
            setError(`Failed to send OTP: ${error.message}`);
            setDisplayRegFields(false);
        } finally {
            setLoading(false);
            setIsVerifying(false);
        }
    };
    
    // const handleSignUp = async (e) => {
    //     e.preventDefault();
    //     setLoading(true);
    //     try {
    //         const formData = new FormData();
    //         formData.append('mobile', mobile);
    //         formData.append('password', password);
    //         formData.append('email', email);
    //         formData.append('name', name);
    //         formData.append('country_code', 1);
    //         console.log(mobile, name, password, email)

    //         const response = await fetch(`${BASE_API_URL}register_user`, {
    //             method: 'POST',
    //             body: formData,
    //         });

    //         if (!response.ok) {
    //             const errorData = await response.json();
    //             throw new Error(errorData.message || 'Registration failed');
    //         }

    //         const data = await response.json();

    //         console.log(data, 'data data');

    //         if (data && data.data && data.data.length > 0) {
    //             sessionStorage.setItem('UserData', data.data[0]);
    //             const modal = document.getElementById('modal-signin');
    //             modal.classList.remove('show');
    //             modal.style.display = 'none';
    //             document.body.classList.remove('modal-open');
    //             const backdrop = document.querySelector('.modal-backdrop');
    //             if (backdrop) backdrop.remove();
    //             window.location.reload();
    //         } else {
    //             console.log('Not found in response');
    //         }
            
    //     } catch (err) {
    //         console.error(err);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const handleSignUp = async (e) => {
        if (e) e.preventDefault();
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('mobile', `+91${mobile}`);
            formData.append('password', password);
            formData.append('email', email);
            formData.append('name', name);
            formData.append('country_code', 1);
            console.log(mobile, name, password, email);
        
            const response = await fetch(`${BASE_API_URL}register_user`, {
                method: 'POST',
                body: formData,
            });
        
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Registration failed');
            }
        
            const data = await response.json();
            console.log(data, 'data data');
        
            if (data && data.data && data.data.length > 0) {
                sessionStorage.clear();
                localStorage.clear(); 
                data.data = data.data.map(item => ({
                    ...item,
                    apikey: data.token
                }));
                console.log(data.data[0])
                sessionStorage.setItem("UserData", JSON.stringify(data.data[0]));

                // const modal = document.getElementById('modal-signin');
                // modal.classList.remove('show');
                // modal.style.display = 'none';
                // document.body.classList.remove('modal-open');
                // const backdrop = document.querySelector('.modal-backdrop');
                // if (backdrop) backdrop.remove();
                // window.location.reload();
    
                Swal.fire({
                    title: 'Registartion Successful!',
                    text: 'Welcome to Giivv account.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(() => {
                    window.location.reload();
                });
            } else {
                Swal.fire({
                    title: 'Registration Failed',
                    text: data.message,
                    icon: 'error',
                    confirmButtonText: 'Retry'
                });
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const verifyOTP = async () => {
        console.log(otp, 'user entered otp')
        if (!otp) {
            setError("Please enter the OTP.");
            return;
        }
    
        if (confirmationResult) {
            // console.log(confirmationResult)
            try {
                const result = await confirmationResult.confirm(otp);
                const user = result.user;
                console.log("OTP verified successfully. User:", user);
    
                await handleSignUp();
            } catch (error) {
                console.error("Error during OTP verification:", error);
                setError(`Failed to verify OTP: ${error.message}`);
            }
        } else {
            setError("No OTP sent yet. Please request an OTP first.");
        }
    };

    const resetFields = () => {
        setName('');
        setEmail('');
        setMobile('');
        setPassword('');
        setOTP('');
        setReferalCode('');
        setDisplayRegFields(false);
    };
    
    // const verifyOTP = async () => {
    //     if (!otpCode) {
    //     setError("Please enter the OTP.");
    //     return;
    //     }

    //     if (confirmationResult) {
    //     try {
    //         const result = await confirmationResult.confirm(otpCode);
    //         const user = result.user;
    //         console.log("OTP verified successfully. User:", user);
    //         alert(`OTP verified successfully. User UID: ${user.uid}`);
    //         handleSignUp();
    //     } catch (error) {
    //         console.error("Error during OTP verification:", error);
    //         setError(`Failed to verify OTP: ${error.message}`);
    //     }
    //     } else {
    //     setError("No OTP sent yet. Please request an OTP first.");
    //     }
    // };

    // const sendOTP = async () => {
    //     if (!mobile) {
    //         setError("Please enter a phone number.");
    //         return;
    //     }

    //     if (!/^\+?\d{10,15}$/.test(mobile)) {
    //         setError("Please enter a valid phone number in E.164 format.");
    //         return;
    //     }
        
    //     setLoading(true);
    //     setIsVerifying(true);
    //     const formattedMobile = `+91${mobile}`;
    //     console.log(formattedMobile);

    //     try {
    //         const result = await signInWithPhoneNumber(
    //             auth,
    //             formattedMobile,
    //             window.recaptchaVerifier
    //         );
    //         setConfirmationResult(result);
    //         setError("");
    //         alert(`OTP sent to ${mobile}`);
    //     } catch (error) {
    //         console.error("Error during OTP sending:", error);
    //         setError(`Failed to send OTP: ${error.message}`);
    //         setIsVerifying(false);
    //     } finally {
    //         setLoading(false);
    //         setIsVerifying(false);
    //     }
    // };


    return (
        <>
            <div>
                {/* <div id="recaptcha-container"></div>
                <div className="col-6 d-flex">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter phone number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <button className="btn btn-info col-3" onClick={sendOTP} disabled={loading}>
                        {loading ? "Sending OTP..." : "Send OTP"}
                    </button>
                </div>
                <br />
                <div className="col-6 d-flex">
                    <input
                    type="text"
                    placeholder="Enter OTP"
                    className="form-control"
                    value={otpCode}
                    onChange={(e) => setOtpCode(e.target.value)}
                    />
                    <button className="btn btn-secondary col-3" onClick={verifyOTP}>Verify OTP</button>
                </div>
                {error && <div className="alert alert-danger fw-bold mt-5">{error}</div>} */}

                <div className="modal fade show" id="modal-signup" tabindex="-1" aria-modal="true" role="dialog" >
                    <div className="modal-dialog modal-dialog-centered modal-md" >
                        <div className="modal-content text-center">
                            <div className="modal-body">
                                <section id="login_div" className={`${displayRegFields ? 'd-none' : 'd-block'}`} style={{ height: '400px' }}>
                                    <div className='d-flex mt-2'>
                                        <h2 className="mb-3 text-start">Registration</h2>
                                        <button type="button" className="btn-close d-flex ms-auto" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <form >
                                        <div className="text-start mb-2">
                                            <label className="form-label mt-4" htmlFor="mobile">Enter Mobile Number</label>
                                            <div className="input-group ">
                                                <span className="input-group-text">
                                                    <img
                                                        src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png"
                                                        alt="India Flag"
                                                        style={{ width: "20px", marginRight: "8px" }}
                                                    />
                                                    +91
                                                </span>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="identity"
                                                    placeholder="Enter Mobile Number"
                                                    id="mobile"
                                                    value={mobile} 
                                                    onChange={(e) => setMobile(e.target.value)}
                                                    onWheel={(e) => e.target.blur(e)}
                                                />
                                            </div>
                                            {/* {isVerifying && (
                                                <div className="text-info mt-2">
                                                    Verifying CAPTCHA, please wait...
                                                </div>
                                                )}
                                                {error && (
                                            )} */}
                                                <div className="text-danger mt-2">
                                                    {error}
                                                </div>
                                            <div id="recaptcha-container"></div>
                                        </div>
                                        <div className="mt-4">
                                            <button className="btn btn-dark rounded-pill">Cancel</button>
                                            <button className="btn bg-secondary text-white rounded-pill ms-4 my-3" type="button" onClick={sendOTP}>Send OTP</button>
                                        </div>
                                        <div id="recaptcha-container"></div>
                                        <p className="mb-0 mt-2">Already have an account?
                                            <a className="text-decoration-none text-blue fs-15 hover" 
                                                // data-bs-toggle="modal"
                                                // data-bs-target="#modal-signin"
                                                style={{ cursor: 'pointer' }}
                                            > Signin Here</a>
                                        </p>
                                    </form>
                                </section>

                                <section className={`${displayRegFields ? 'd-block' : 'd-none'}`}>
                                    <div className='d-flex'>
                                        <h2 className="mb-3 text-start">Registration</h2>
                                        <button type="button" className="btn-close d-flex ms-auto" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <form >
                                        <div className="text-start mb-2">
                                            <label className='form-label' for="mobile">OTP</label>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                name="identity"
                                                placeholder="Enter OTP" 
                                                id="mobile" 
                                                value={otp} 
                                                onChange={(e) => setOTP(e.target.value)}
                                                onWheel={(e) => e.target.blur(e)}
                                            />
                                        </div>
                                        <div className="text-start mb-2">
                                            <label className='form-label' for="name">Name</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="identity"
                                                placeholder="Enter User Name" 
                                                id="name" 
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </div>
                                        <div className="text-start mb-2">
                                            <label className='form-label' for="email">Email</label>
                                            <input 
                                                type="email" 
                                                className="form-control" 
                                                name="identity"
                                                placeholder="Enter Email" 
                                                id="email" 
                                                value={email} 
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-4 text-start">
                                            <label className='form-label' for="loginPassword">Password</label>
                                            <input 
                                                type="password" 
                                                className="form-control" 
                                                name="password" 
                                                placeholder="Password"
                                                id="loginPassword" 
                                                value={password} 
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-4 text-start">
                                            <label className='form-label' for="loginPassword">Friends Code</label>
                                            <input 
                                                type="password" 
                                                className="form-control" 
                                                name="password" 
                                                placeholder="Friends Code"
                                                id="loginPassword" 
                                                value={referalCode} 
                                                onChange={(e) => setReferalCode(e.target.value)}
                                            />
                                        </div>
                                        {error && <p className='fw-bold text-danger'>*{error}</p> }
                                        <footer>
                                            <button type="button" data-bs-dismiss="modal" aria-label="Close"
                                                className="btn btn-dark rounded-pill"
                                                onClick={resetFields}    
                                            >Cancel</button>
                                                <button type="button" onClick={verifyOTP} className="btn bg-secondary text-white ms-2 rounded-pill" disabled={loading}>
                                                    {loading ? 'Registring...' : 'Register'}
                                                </button>
                                        </footer>
                                        <br/>
                                        <p className="mb-0">Already have an account?
                                            <a className="text-decoration-none text-blue fs-15 hover" 
                                                // data-bs-toggle="modal" 
                                                // data-bs-target="#modal-signin"
                                                style={{ cursor: 'pointer' }}
                                            >Signin Here</a>
                                        </p>
                                    </form>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <SignIn /> */}
        </>
    );
};

