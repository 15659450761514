
import React from "react";
import Base from "../Config/Base";

export default function OrderReceipt () {
    return (
        <div className="container">
             <div className={`row`}>
                <div className="col-md-12 orders-section settings-tab-content">
                    <div className="mb-4 border-0 shadow-xl p-10">
                        <div className="card-header bg-white">
                            <div className="d-flex justify-content-between">
                                <div className="col">
                                    <p className="text-muted"> Order ID<span className="font-weight-bold text-dark"> : </span></p>
                                    <p className="text-muted"> Place On<span className="font-weight-bold text-dark"> : </span> </p>
                                </div>
                                <div className="flex-col my-auto">
                                    <h6 className="ml-auto mr-3">
                                        <a className="btn btn-xs btn-outline-primary me-3" >Invoice</a>
                                    </h6>
                                </div>
                            </div>
                            <br />
                        </div>
                        <div className="card-body">
                            {/* {viewOrderDetails?.order_items?.length > 0 ? (
                                viewOrderDetails.order_items.map((item, index) => (
                                    <div key={index}>
                                        <div className="media d-flex flex-sm-row mb-2">
                                            <div className="media-body">
                                                <Link to={`/product-details/${item.product_id}`} className="text-decoration-none">
                                                    <h5 className="bold">{index + 1}. {item.name}</h5>
                                                </Link>
                                                <p className="text-muted mb-0">Quantity: {item.quantity}</p>
                                                <h4 className="mt-3 mb-2 bold">
                                                    <span className="mt-5"><i>₹</i></span> {item.price}
                                                    <span className="text-danger mx-2 text-decoration-line-through">{item.main_price}</span>
                                                </h4>
                                            </div>
                                            <div className="align-self-center img-fluid ms-auto">
                                                <Link to={`/product-details/${item.product_id}`}>
                                                <img
                                                    src={item.image}
                                                    width="180"
                                                    height="180"
                                                    className="logo-fit rounded"
                                                />
                                                </Link>
                                            </div>
                                        </div>

                                        <section className="wrapper bg-light mb-2">
                                            <div className="container py-8">
                                                <div className="row gx-lg-8 gx-xl-12 gy-6 process-wrapper line" id="progressbar">
                                                {item.status?.length > 0 ? (
                                                    item.status.map((statusItem, statusIndex) => (
                                                    <div className={`col-md-6 col-lg-2 ${statusIndex === 0 ? "active" : ""}`} key={statusIndex}>
                                                        <span className="icon btn btn-circle btn-secondary pe-none mb-2">
                                                            <span className="number" id={`step${statusIndex + 1}`}>{statusIndex + 1}</span>
                                                        </span>
                                                        <h6 className="mb-0 texxt-uppercase" style={{ textTransform: 'uppercase' }}>{statusItem[0]}</h6>
                                                        <p className="mb-0">{statusItem[1]}</p>
                                                    </div>
                                                    ))
                                                ) : (
                                                    <p>No status available</p>
                                                )}
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                ))
                            ) : (
                                <p>No order items available.</p>
                            )} */}

                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <h6 className="h5">Shipping Details</h6>
                                    <hr className="" />
                                    {/* <span>{viewOrderDetails && viewOrderDetails.username}</span> <br/>
                                    <span>{viewOrderDetails && viewOrderDetails.address}</span> <br/>
                                    <span>{viewOrderDetails && viewOrderDetails.mobile}</span> <br/>
                                    <span>{viewOrderDetails && viewOrderDetails.delivery_time}</span> <br/>
                                    <span>{viewOrderDetails && viewOrderDetails.delivery_date}</span> <br/> */}
                                </div>
                                <div className="col-md-6">
                                    <h6 className="h5">Price Details</h6>
                                    <hr className="" />
                                    <div className="table-responsive">
                                        <table className="table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <th>Total Order Price</th>
                                                    {/* <td>+ ₹ {viewOrderDetails && viewOrderDetails.total}</td> */}
                                                </tr>
                                                <tr>
                                                    <th>Delivery Charge</th>
                                                    {/* <td>+ ₹ {viewOrderDetails && viewOrderDetails.delivery_charge}</td> */}
                                                </tr>
                                                <tr className="d-none">
                                                    <th>Tax - (0%)</th>
                                                    {/* <td>+ ₹ {viewOrderDetails && viewOrderDetails.total_tax_amount}</td> */}
                                                </tr>
                                                <tr>
                                                    <th>Wallet Used</th>
                                                    {/* <td>- ₹ {viewOrderDetails && viewOrderDetails.wallet_balance}</td> */}
                                                </tr>
                                                <tr className="h6">
                                                    <th>Final Total</th>
                                                    {/* <td>₹ {viewOrderDetails && viewOrderDetails.final_total}<span className="small text-muted"> via ({viewOrderDetails && viewOrderDetails.payment_method}) </span></td> */}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}