// routes.js
import MyAccount from "./Components/MyAccount/MyAccount";
import Home from "./Components/Home/Home";
import AboutUs from "./Components/AboutUs/AboutUs";
import Products from "./Components/Products/Products";
import ProductDetails from "./Components/Products/ProductDetails";
import BillingDetails from "./Components/Billing/BillingDetails";
import ViewCart from "./Components/Cart/ViewCart";
import TermsAndConditions from "./Components/Settings/TermsAndConditions";
import PrivacyPolicy from "./Components/Settings/PrivacyPolicy";
import ReturnPolicy from './Components/Settings/ReturnPolicy';
import ContactUs from './Components/Settings/ContactUs';
import ThankYouForOrder from "./Components/Billing/ThankYou";
import OrderReceipt from "./Components/Billing/OrderReceipt";

const routes = [
    { path: "/my-account", element: <MyAccount /> },
    { path: "/about-us", element: <AboutUs /> },
    { path: "/products/:CategoryId", element: <Products /> },
    { path: "/product-details/:ProductId", element: <ProductDetails /> },
    { path: "/billing-details", element: <BillingDetails /> },
    { path: "/order-receipt", element: <OrderReceipt /> },
    { path: "/thank-you/:OrderId", element: <ThankYouForOrder /> },
    { path: "/view-cart", element: <ViewCart /> },
    { path: "/terms-and-conditions", element: <TermsAndConditions /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/return-policy", element: <ReturnPolicy /> },
    { path: "/contact-us", element: <ContactUs /> },
    { path: "/", element: <Home /> },
];

export default routes;

