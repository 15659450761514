import React, { useState, useEffect } from "react";
import Base from "../Config/Base";
import { BASE_API_URL } from "../Config/Config";
import axios from "axios";
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom";
import AddToFavorite from "../Favorites/AddFavorite";
import AddToCart from "../Cart/AddCart";


export default function Home () {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [sliderImages, setSliederImages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoriesSlice, setCategoriesSlice] = useState([]);
    const [showAllCategories, setShowAllCategories] = useState(false);
    const [error, setError] = useState("");
    const [occasionProducts, setOccasionProducts] = useState([]);
    // const [productTags, setProductTags] = useState([]);
    const [recomendedProducts, setRecomendedProducts] = useState([]);
    const [selectedFavProdId, setSelectedFavProdId] = useState('');
    const [selectedCartProdId, setSelectedCartProdId] = useState('');
    const [userData, setUserData] = useState([]);
    
    useEffect(() => {
        const storedUserData = sessionStorage.getItem('UserData');
        if (storedUserData) {
            try {
                setUserData(JSON.parse(storedUserData));
            } catch (error) {
                console.error("Error parsing storedUserData:", error);
            }
        }
    }, []);
    
    // const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchSliderImages = async () => {
            try {
                const response = await axios.post(`${BASE_API_URL}get_slider_images`, {
                    // headers: {
                    //     "Authorization": `${JWT_TOKEN}`,
                    // },
                });
                setSliederImages(response.data.data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchSliderImages();
    }, []);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.post(`${BASE_API_URL}get_categories`, {});
                setCategoriesSlice(response.data.data.slice(0, 8));
                setCategories(response.data.data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchCategories();
    }, []);

    // useEffect(() => {
    //     const fetchProducts = async () => {
    //         try {
    //             const formData = new FormData();
    //             formData.append("tags", "HOUSEWARMING, ANNIVERSARY, BABY SHOWER, CONGRATULATIONS, BIRTHDAY, GET WELL SOON, FAREWELL, WEDDINGS");
    //             const response = await axios.post(`${BASE_API_URL}get_products`, formData, {
    //                 headers: {
    //                     "Authorization": `${JWT_TOKEN}`,
    //                     "Content-Type": "multipart/form-data",
    //                 },
    //             });
    
    //             // const products = response.data.data;
    //             // const tags = response.data.tags;
    
    //             // const occasionData = products.filter(product =>
    //             //     tags.some(tag => product.name.toLowerCase() === tag.toLowerCase())
    //             // );                                
                
    //             // console.log(tags, 'tagstags');
    //             // console.log(occasionProducts, 'Matched Products for Occasions');
    
    //             // // setProducts(products);
    //             // setProductTags(tags);
    //             setOccasionProducts(response.data.data);
    //         } catch (err) {
    //             console.log('getting error', err);
    //         }
    //     };
    
    //     fetchProducts();
    // }, []);

    useEffect(() => {
        const fetchRecomendedProduct = async () => {
            try {
                const formData = new FormData();
                formData.append("section_id", 1);

                const response = await axios.post(`${BASE_API_URL}get_sections`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                setRecomendedProducts(response.data.data[0].product_details);
                console.log(response.data.data[0].product_details, 'recomended productss..sssssssssssssssssssss..........');
            } catch (err) {
                console.log('failed fetching recomended producut..!');
            }
        };

        fetchRecomendedProduct();
    }, []);

    useEffect(() => {
        const fetchOoccasionProduccts = async () => {
            try {
                const formData = new FormData();
                formData.append("section_id", 8);

                const response = await axios.post(`${BASE_API_URL}get_sections`, formData, {
                    headers: {
                        // Authorization: `${}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
                setOccasionProducts(response.data.data[0].product_details);
                // console.log(response.data.data[0].product_details, 'data fro occasions')
            } catch (err) {
                console.log('failed fetching occasion producut..!');
            }
        };

        fetchOoccasionProduccts();
    }, []);

    // const responsive = {
    //     superLargeDesktop: {
    //       // the naming can be any, depends on you.
    //       breakpoint: { max: 4000, min: 3000 },
    //       items: 5
    //     },
    //     desktop: {
    //       breakpoint: { max: 3000, min: 1024 },
    //       items: 3
    //     },
    //     medium: {
    //       breakpoint: { max: 1024, min: 768 },
    //       items: 4 // Set 4 items for medium devices (between 768px and 1024px)
    //     },
    //     tablet: {
    //       breakpoint: { max: 1024, min: 464 },
    //       items: 2
    //     },
    //     mobile: {
    //       breakpoint: { max: 464, min: 0 },
    //       items: 1
    //     }
    //   };
      
      

    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    // };

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1
        }
    };

    const handleClickFavIcon = (id) => {
        setSelectedFavProdId(id);
    };
    
    const handleClickCartBtn = (id) => {
        setSelectedCartProdId(id);
    };

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
    };
    return (
        <Base>
            <style>
                {`
                    .carousel-item {
                        height: 100%;
                    }

                    .carousel-inner img {
                        width: 100%;  /* Ensure the image spans the full width */
                        height: 100%; /* Ensure the image spans the full height */
                        object-fit: cover; /* Prevent image distortion and ensure it covers the space */
                    }

                    @media (max-width: 768px) {
                        .carousel-inner img {
                            height: 20vh;  /* Slightly reduce the height for tablets */
                        }
                    }

                    @media (max-width: 576px) {
                        .carousel-inner img {
                            height: 20vh;  /* Further reduce the height for smaller mobile devices */
                        }
                    }
                `}
            </style>

            <div id="carouselExampleControls" className="carousel slide container" data-bs-ride="carousel" style={{ zIndex: '-100' }}>
                <div className="carousel-indicators">
                    {sliderImages &&
                        sliderImages.map((_, index) => (
                            <button
                                type="button"
                                data-bs-target="#carouselExampleControls"
                                data-bs-slide-to={index}
                                className={index === 0 ? "active" : ""}
                                aria-label={`Slide ${index + 1}`}
                                key={index}
                            ></button>
                        ))}
                </div>

                <div className="carousel-inner">
                    {sliderImages &&
                        sliderImages.map((item, index) => (
                            <div
                                className={`carousel-item ${index === 0 ? "active" : ""}`}
                                key={index}
                            >
                                <img
                                    src={item.image}
                                    className="d-block w-100"
                                    alt=""
                                />
                            </div>
                        ))}
                </div>

                <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev"
                >
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="next"
                >
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

            <div className="container py-5">
                <h2 className="text-center mb-4 fw-bold font-rock">SHOP BY CATEGORY</h2>
                <div className="row justify-content-center py-3">
                    <div className="col-10 m-auto">
                        <div className="row d-flex justify-content-start g-5">
                            {(showAllCategories ? categories : categoriesSlice)?.map((item, index) => (
                                <div key={index} className="category-card text-decoration-none text-dark text-center h-100 col-lg-3 col-md-4 col-6 my-3">
                                    <Link to={`products/${item.id}`} style={{ textDecoration: 'none' }} className="">
                                    <img 
                                        src={item.image} 
                                        className="img-fluid mb-3 rounded"
                                        alt={item.name}
                                    />
                                        <p className="fw-bold font-rock fs-5">{item.name}</p>
                                    </Link>
                                </div>
                            ))}
                        </div>
                        <div className="text-center mt-4">
                            <button
                                onClick={() => setShowAllCategories(!showAllCategories)}
                                className="bg-secondary border-0 fs-5 py-2 px-4 text-white fw-bold font-rock"
                            >
                                {showAllCategories ? "SHOW LESS" : "VIEW ALL CATEGORIES"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="recommended-products py-5 bg-primary">
                <h2 className="text-center mb-4 fw-bold text-uppercase text-white font-rock">Recommended Products</h2>
                <div className="row w-100 justify-content-center py-3">
                    <div className="col-10 d-flex">
                        <OwlCarousel 
                            key={recomendedProducts?.length || 0}
                            className='owl-theme' 
                            loop 
                            margin={10} 
                            nav={false}
                            // items={4}
                            autoplay={true}
                            responsive={{
                                0: {
                                    items: 1,
                                },
                                600: {
                                    items: 3,
                                },
                                1000: {
                                    items: 4,
                                }
                            }}
                        >
                            {recomendedProducts && recomendedProducts?.map((item, index) => (
                                <div 
                                    className="item" 
                                    style={{ marginRight: '10px', marginLeft: '10px' }} 
                                    key={index}
                                >
                                    <div className="product-card" style={{ height: '100%' }} >
                                        <div className="">
                                            <div className={`product-fav ${userData.id ? 'd-none' : 'd-block'}`}  
                                                data-bs-toggle="modal"    
                                                data-bs-target="#modal-signin"
                                            >
                                                <i className="fa-regular fa-heart"></i>
                                            </div>
                                            <div className={`product-fav ${userData.id ? 'd-block' : 'd-none'}`}  onClick={() => handleClickFavIcon(item.id)}>
                                                <i className="fa-regular fa-heart"></i>
                                            </div>
                                            <div className="product-fav mt-5">
                                                <Link to={`/product-details/${item.id}`}>
                                                    <i className="fa-regular fa-eye text-dark"></i>
                                                </Link>
                                            </div>

                                            <Link to={`/product-details/${item.id}`}>
                                                <img src={item.image} alt="Purple Majesty" style={{ height: '300px', width: '100%' }}/>
                                            </Link>
                                            <div className="product-card-content p-md-3 font-rock bg-white">
                                                <h5 className="fw-bold fs-6" title={`${item.name}`}>{item.name.length > 19 ? `${item.name.substring(0, 19)}...` : item.name}</h5>
                                                <div className="d-flex justify-content-center">
                                                    <p>₹{item.min_max_price.special_price}</p>
                                                    <p className="text-danger text-decoration-line-through ms-2">₹{item.min_max_price.max_price}</p>
                                                </div>
                                                <button className={`btn add-to-cart text-white d-flex m-auto ${userData.id ? 'd-none' : 'd-block'}`} style={{ backgroundColor: '#9c786c' }}
                                                    data-bs-toggle="modal"    
                                                    data-bs-target="#modal-signin" 
                                                >Add to Cart</button>
                                                <button className={`btn add-to-cart text-white d-flex m-auto ${userData.id ? 'd-block' : 'd-none'}`} style={{ backgroundColor: '#9c786c' }}
                                                        onClick={() => handleClickCartBtn(item.variants[0].id)}
                                                >Add to Cart</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>
                    </div>
                </div>
            </div>

            <div className="container p-5 py-5">
                <h2 className="text-center mb-2 fw-bold text-uppercase font-rock">Elevate the Occasion</h2>
                <h3 className="text-center fw-light mb-4 text-dark h3">Celebrate every moment with curated, thoughtful gifting solutions.</h3>
                <div className="row justify-content-center py-4">
                    {occasionProducts && occasionProducts?.map((product, index) => (
                        product.other_images.map((image, idx) => (
                            <div className="col-lg-3 col-md-4 col-6 my-4" key={index}>
                                <div className="text-center">
                                    <div key={idx}>
                                        <img 
                                            src={image} 
                                            className="img-fluid rounded-circle" 
                                            style={{ width: '90%', cursor: 'pointer' }} 
                                            alt="Occasion Image" 
                                            onClick={() => handleImageClick(image)}
                                        />
                                        <h5 className="fw-bold mt-3 font-rock" style={{ fontSize: 'medium' }}>
                                            {product.tags[idx]}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        )) 
                    ))}
                </div>
                {selectedImage && (
                    <div
                        className="modal show"
                        style={{
                            display: 'block',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            backdropFilter: 'blur(3px)',
                            zIndex: 2050,
                        }}
                        onClick={handleCloseModal}
                    >
                        <div
                            className="modal-dialog modal-dialog-centered"
                            style={{
                                position: 'relative',
                                zIndex: 2060,
                            }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="modal-content bg-transparent border-0">
                                <div className="modal-body text-center">
                                    <button
                                        type="button"
                                        className="btn text-danger border-0 fs-1 fw-bold position-absolute top-0 end-0 m-3"
                                        onClick={handleCloseModal}
                                        style={{ backgroundColor: 'transparent' }}
                                    >
                                        &times;
                                    </button>
                                    <img
                                        src={selectedImage}
                                        className="img-fluid rounded-circle"
                                        style={{
                                            width: '90%',
                                            border: '3px solid white',
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        }}
                                        alt="Large Occasion Image"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <AddToFavorite productId={selectedFavProdId} />
            <AddToCart productVariantId={selectedCartProdId} qty={1} />
            
        </Base>
    )
}