import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BASE_API_URL } from "../Config/Config";

export default function AddToCart({ qty, productVariantId }) {

    const [userData, setUserData] = useState([]);

    useEffect(() => {
        const storedUserData = sessionStorage.getItem('UserData');
        if (storedUserData) {
            try {
                setUserData(JSON.parse(storedUserData));
            } catch (error) {
                console.error("Error parsing storedUserData:", error);
            }
        }
    }, []);
    
    useEffect(() => {
        const handleAddToCart = async (qty, productVariantId) => {

            const url = `${BASE_API_URL}manage_cart`;
            if (!productVariantId) return;
            
            console.log(productVariantId, qty, 'id triggered in cart fun');
            try {
                const formData = new FormData();
                formData.append("user_id", userData.id);
                formData.append("qty", qty);
                formData.append("product_variant_id", productVariantId);
                console.log("productVariantId: ", productVariantId, "qty: ", qty, "userid: ", userData.id);
        
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${userData.apikey}`,
                        // Authorization: `${JWT_TOKEN_POST}`,
                    },
                    body: formData,
                });
                console.log(response, 'response from api manage_cart');
        
                if (response.ok) {
                    const data = await response.json();
                    console.log("Added to cart:", data);
                    if (!data.error) {
                        alert(data.message);
                        window.location.reload()
                    }
                    else {
                        alert(data.message);
                    }
                } else {
                    console.error("Error adding to cart:", response.statusText);
                    alert("Failed to add product to cart.");
                }
            } catch (error) {
                console.error("Error adding to cart:", error);
                alert("An error occurred while adding to cart.");
            }
        };

        if (qty && productVariantId) {
            handleAddToCart(qty, productVariantId);
        }
    }, [productVariantId]);

}

AddToCart.propTypes = {
    qty: PropTypes.string.isRequired,
    productVariantId: PropTypes.string.isRequired,
};
